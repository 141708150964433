import React, { Component } from 'react';
import { Container } from 'semantic-ui-react';

class MainScreen extends Component {

  render() {
  
    return (
      <Container text textAlign='center' className="center-vertical">
        <p className="main-title">No cierre esta ventana. Por favor espere la apertura de la siguiente votación.</p>
      </Container>
    );
  }
}

export default MainScreen;
