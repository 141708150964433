import React, { Component } from 'react';
import { PH_COLLECTION, ASSEMBLIES_COLLECTION, QUORUM_COLLECTION, FIREBASE_DB } from '../../../constants';
import { Container } from 'semantic-ui-react';
import { realTime } from '../../utils/realTime';
import { timeOutTimer } from '../../utils/timeOutTimer';
import { logger } from '../../utils/logger';

class QuorumScreen extends Component {

    constructor(props) {
        super(props);

        this.state={
            result: 0
        }
        
        const PHid = props.PHid;
        const assemblyId = props.assemblyId;

        const quorumDocument = FIREBASE_DB.collection(PH_COLLECTION).doc(PHid)
                                    .collection(ASSEMBLIES_COLLECTION).doc(assemblyId)
                                    .collection(QUORUM_COLLECTION).doc("results");
        
        const quorumRTFail = "No se puede conectar a servidor de QuorumRT";
        
        this.quorumTimer = timeOutTimer(`${quorumRTFail}0x2`)
        
        let quorumRTFunction = doc =>{
            const data = doc.data();
            if(data){
                const value = data.value;
                if(value){
                    this.setState({
                        result:value
                    })
                }
            }
            logger("quorumRT socket ok", "log");
            clearTimeout(this.quorumTimer);

        }
        this.quorumListener = realTime(quorumDocument, quorumRTFunction, `${quorumRTFunction}0x1`, this.quorumTimer);

    }

    componentWillUnmount(){
        this.quorumListener();
        clearTimeout(this.quorumTimer);
    }

    render() {
        
        return (
            <Container text textAlign='center' className="center-vertical">
            <p className="main-title cancel-padding cancel-margin">Quorum Reportado:</p>
            <p className="result">{((this.state.result).toFixed(3))*1}</p>
          </Container>
        );
    }
    }

export default QuorumScreen;
