import React, { Component } from 'react';
import Loading from './Loading';
import Axios from 'axios';
import { Container, Checkbox, Button } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import Timer from '../Timer';
import Results from '../Results';
import { deleteSession } from '../../utils/deleteSession';
import { logger } from '../../utils/logger';



var conf = require('../../../conf');

class VotationScreen extends Component {

  constructor(props) {
    super(props);
    this.state ={
      ready: false,
      question: null,
      answers: [],
      type: null,
      maxAnswers: 0,
      time: 0,
      sendingVotes: false,
      disabledVotes: false
    }


    this.cantSelected = 0;
    this.seletedIds = [];
  }

  getQuestions(){
    const url = `${conf.baseURL}/question?PHid=${this.props.PHid}&assemblyId=${this.props.assemblyId}&questionId=${this.props.questionId}`

    Axios.get(url, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
    .then(res => {
    if(!res.data.error){
        const message = res.data.message;  
        const question = message.data.question;
        const answers = message.answers.map(obj => {
          return{
            id:obj.id,
            text:obj.data.text,
            disabled: false
          }
        });
        const type = message.data.question_type;
        const maxAnswers = message.data.multipleAnswer;
        const time = message.data.time;

        this.setState({
          ready: true,
          question,
          answers,
          type,
          maxAnswers,
          time
        })
        

    }
    }).catch(err => {
      const resData  = err.request; 
      const message = JSON.parse(resData.response)["message"];
      console.error(message);
      toast.error(`${message}`);
      logger(`Error en ${url}. ${err}. ${message}.`)
      if(resData.status === 401){
          deleteSession();
      }
    });

  }

  componentDidMount(){
    
    if(this.props.questionId){
      this.getQuestions();

    }
  }

  componentDidUpdate(prevProps, prevState, snapshot){

    if(this.props.questionId){
      if(this.props.questionId !== prevProps.questionId){
        this.getQuestions();
      }
    }
  }

  handleChange = (e, { checked }) => {
    const targetId = e.target.id;
    if(checked){
      this.cantSelected ++;
      this.seletedIds.push(targetId)
    }else{
      this.cantSelected --;
      this.seletedIds = this.seletedIds.filter(obj => obj !== targetId);
    }
    let tempAnswers = this.state.answers;

    if(this.cantSelected === this.state.maxAnswers){
      for(let a = tempAnswers.length -1; a >= 0; a--){
        if(!this.seletedIds.includes(tempAnswers[a].id)){
          tempAnswers[a].disabled = true;
        }
      }
    }else if (this.cantSelected === (this.state.maxAnswers - 1)){
      for(let a = tempAnswers.length -1; a >= 0; a--){
        tempAnswers[a].disabled = false;
      }
    }
    
    this.setState({
      answers: tempAnswers
    })
  }

  handleClik= () =>{
    this.disablaAndLoadignButton(true);

    const url = `${conf.baseURL}/votation`;
    const data = {
      PHid: this.props.PHid,
      assemblyId: this.props.assemblyId,
      estateId: this.props.estateId,
      questionId: this.props.questionId,
      vote: this.seletedIds
  }

  Axios.post(url, data, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
  .then(res =>{
      console.info("Votacion Exitosa");
      toast.info('Votacion Exitosa');
      this.disablaAndLoadignButton(false)
  })
  .catch(error => {

    const resData = error.request;
    try{
      if(resData.status !== 401){
        toast.error('No se ha podido reportar el quorum');
      }else{
        const message = JSON.parse(resData.response)["message"];
        console.error(message);
        toast.error(`${message}`);
        deleteSession();
      }
    }catch(error){
      toast.error('Error');
      console.error(error);
      logger(`Error en ${url}. ${error}`)
    }
    
    this.disablaAndLoadignButton(false)

  });

  }

  disablaAndLoadignButton(status){
    this.setState({
      sendingVotes: status,
      disabledVotes: status
    });
  }

  renderQuestions(){

    let cantOpciones;
    if (this.state.maxAnswers === 1){
      cantOpciones = <p>Pregunta con unica respuesta</p>
    }else{
    cantOpciones = <p>Pregunta con multiple respuesta, máximo {this.state.maxAnswers} opciones.</p>
    }

    const answers = this.state.answers.map(obj=>{
      return (
        <Container >
          <Checkbox
            textAlign='left'
            label = {`${obj.text}`}
            id = {obj.id}
            onChange={this.handleChange}
            disabled={obj.disabled}
            key = {obj.id}
            className = "tower-check"
          />
        </Container>
      );
    })

    let typeContainer;
    if(this.props.showType){
      typeContainer =(<p>Tipo de Pregunta: {this.state.type}</p>);
    }
    
    let results;
    if (this.props.showResults){
      results = (<Results className='user-results' PHid={this.props.PHid} assemblyId={this.props.assemblyId} questionId={this.props.questionId} showQuestion={false} maxAnswers={this.state.maxAnswers}/>);
    }
    
    return (

      
      <Container>
        <p className="main-title cancel-margin">{this.state.question}</p>
        {cantOpciones}
        <Container className="info-container">
          {typeContainer}
          <Timer className='user-timer' activeTimer={this.props.activeTimer} time={this.state.time} showTimer={this.props.showTimer} />
        </Container>
        <Container className="answers-container">
          {answers}
        </Container>
        {results}
        <Button
          className ="btn-user-primary"
          content ="Enviar Voto(s)"
          onClick = {this.handleClik}
          disabled={this.state.disabledVotes}
          loading = {this.state.sendingVotes}
          primary
        />
      </Container>
    );
  }

  render() {
    let returnedComponet;
    if(this.state.ready)
      returnedComponet = this.renderQuestions();
    else{
      returnedComponet = <Loading/>;
    } 
    
    return returnedComponet;
  }
}

export default VotationScreen;
