import React, { Component } from 'react';
import { Button, Form, Grid, Segment, Header } from 'semantic-ui-react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FIREBASE_AUTH } from '../../constants';

var conf = require('../../conf');

class Login extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
    };
  } 
  
  handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
  }

  handleLogin = () => {

    const { email, password } = this.state;

    FIREBASE_AUTH.signInWithEmailAndPassword(email,password).then(user => {
      if(user != null){
        var user = FIREBASE_AUTH.currentUser;
        const email = user.email;
        const url = `${conf.baseURL}/login/login-admin`
        const data = {
            email
        }

        axios.post(url, data )
        .then(res =>{
          const token = res.data.message.token;
          localStorage.setItem('usuario', user.email);
          localStorage.setItem('session',  token );
          this.props.verificar();
        })
        .catch(error => {
            console.error(error);
            toast.error('Error, vuelva a intentarlo');
        });
      }
    }).catch(function(error) {
      toast.error(`${error.message}`,
      {
        position: 'top-center',
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    });
  };

  render() {
    return (
        <Grid textAlign='center' style={{ height: '100%', margin: '40px' }} verticalAlign='middle' color='purple' inverted>
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as='h2' color='black' textAlign='center' inverted>
              Iniciar Sesión como Administrador
            </Header>
              <Form>
                <Segment stacked>
                  <Form.Input fluid icon='user'
                    iconPosition='left'
                    placeholder='Correo electronico'
                    name='email'
                    type='email'
                    onChange={this.handleInput}
                  />
                  <Form.Input
                    fluid
                    icon='lock'
                    iconPosition='left'
                    placeholder='Contraseña'
                    type='password'
                    name='password'
                    onChange={this.handleInput}
                  />
                </Segment>
              </Form>
              <Grid.Row style={{ margin: '20px' }}>
                <Button color='black'
                  inverted
                  size='large'
                  onClick={()=>{this.handleLogin();}}>
                  Iniciar Sesión
                </Button>
              </Grid.Row>
          </Grid.Column>
        </Grid>
    );
  }
}

export default Login;
