import React, { Component } from 'react';
import {Container,Grid,Header,Segment,Image} from 'semantic-ui-react';
import voTowerLogo from '../Images/SVG-VoTOWER.svg';

class PageFooter extends Component {

  borrarPh=()=>{

  }
  render() {
    return (
        <Segment inverted vertical style={{ padding: '20px 0px' }}>
            <Container>
                {/*<Grid divided inverted stackable>*/}
                <Grid centered columns={1}>
                    <Grid.Row>
                            <Header as='a' 
                            href='http://www.TOWER.com.co'
                            inverted>
                                ¿Quieres usar <Image src={voTowerLogo} className="footer-image"/> para tu conjunto?, contáctanos haciendo clic aquí o visítanos en www.TOWER.com.co
                            </Header>
                    </Grid.Row>
                    <Grid.Row>
                            <Header as='a' 
                            href='http://www.TOWER.hosting'
                            inverted>
                                Copyright © 2020 | TOWER -Logística Inteligente- | Powered by TOWER.hosting
                            </Header>
                    </Grid.Row>
                </Grid>
            </Container>
        </Segment>
    );
  }
}

export default PageFooter;