import React, { Component } from 'react';
import axios from 'axios';
import { logger } from '../utils/logger';

var conf = require('../../conf');


class Timer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            time: props.time,
        }

        this.timer = null;
        this.offset=null;
       
    }

    secondstoMMSS(totalSeconds){
        const minutes =  Math.floor(totalSeconds/60);
        const seconds = totalSeconds%60;

        const minutesString = this.numberTowDigitsString(minutes);
        const secondsString = this.numberTowDigitsString(seconds);

        return `${minutesString}:${secondsString}`;
    }

    async getCurrentTime() {

        const url = `${conf.baseURL}/server_date`
        let results = 0;
        await axios.get(url, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
        .then(res => {
            const currentTime = res.data.message;
            results=currentTime;
        }).catch(err => {
            console.error(err);
            logger(`Error en ${url}. ${err}.`)

            //toast.error(`${err.data.message}`);
        });
        return results;
    }

    async startTimer(){ 
        const currentTime = await this.getCurrentTime();
        const difSeconds = Math.floor(currentTime/1000) - Math.floor(this.props.activeTimer/1000);
        let realtime = this.props.time - difSeconds;
        clearInterval(this.timer);        
        if(realtime < 0){
            realtime =0;
        }
        this.setState({
            time: realtime
        })
        
        this.timer = setInterval(() => {
            if(this.state.time <= 0){
                clearInterval(this.timer);
            }else{
                let time = this.state.time - 1;
                this.setState({
                    time
                })
            }

        }, 1000);
    }

    stopTimer(){
        clearInterval(this.timer);
    }

    componentDidMount(){
        if(this.props.activeTimer !== 0){
            this.startTimer();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        
        if(prevProps.activeTimer !== this.props.activeTimer){
            if(this.props.activeTimer !== 0){
                this.startTimer();
            }else{
                this.stopTimer();
            }
        }
    }

    numberTowDigitsString(number){
        if (number < 10){
            return `0${number}`;
        }
        return `${number}`;
    }


    render() {
        
        let returnedComponet = null;

        if(this.props.showTimer === true){
            returnedComponet = (
            <p className={this.props.className}>Tiempo Restante: {this.secondstoMMSS(this.state.time)}</p>
            );
        }
        
        return returnedComponet;
    }
    }

export default Timer;