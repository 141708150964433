import React, { Component } from 'react';
import { Container, Button, CardGroup, Accordion, Icon, Divider, Confirm} from 'semantic-ui-react';
import Axios from 'axios';
import CardAssembly from '../Assemblies/CardAssembly.js';
import NewAssembly from '../Assemblies/NewAssembly.js';
import EditPh from './EditPh';
var conf = require('../../conf');

class PhDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      openCreateAssembly: false,
      openPhEdit: false,
      idPh: this.props.id,
      info: {},
      openConfirm: false,
      activeIndex: 0,
    };
  }

  show = () => this.setState({ openConfirm: true })
  handleCancel = () => this.setState({ openConfirm: false })

  componentDidMount() {
    if (!this.props.info) {
      this.getInfoPh();
    }
    else {
      this.setState({ info: this.props.info });
    }
    if (this.props.admin) {
      this.getAssembliesxId();
    }
    this.getAssembliesxId(); //para probar read assemblies

  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  getInfoPh = () => {
    Axios.get(`${conf.baseURL}/ph?PHid=${this.props.id}`, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
        let datos = res.data.message;
        this.setState({ info: datos });
        this.render();
      }).catch(err => console.log(err));
  }


  getAssembliesxId = () => {
    Axios.get(`${conf.baseURL}/assembly?PHid=${this.props.id}`, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
        this.setState({ listaAssemblies: res.data.message });
      }).catch(err => console.log(err));
  }

  onClosephEdit = () => {
    this.setState({
      openPhEdit: false
    });
  }

  onCloseCreateAssembly = () => {
    this.setState({
      openCreateAssembly: false
    });
  }

  createNewAssembly = () => {
    this.setState({ openCreateAssembly: true });
  }

  phEdit = () => {
    this.setState({ openPhEdit: true });
  }

  deletePh = () => {
    let formData={
      'id':this.props.id
    }
    Axios.delete(`${conf.baseURL}/ph/`, {data:formData, headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
        window.location = window.location.origin;
      }).catch(err => console.log(err));
    this.handleCancel();
  }

  render() {
    const { activeIndex} = this.state;
    //if (this.props.admin) {
      return (
        <Container>
          <h1>{this.state.info.name}</h1>
          <Divider />
          <Button onClick={this.props.viewPhs}><Icon name='arrow left'/> Ver Phs </Button>
          <Divider />
          <Button onClick={this.phEdit}><p>Editar Ph <Icon name='edit'/> </p></Button>
          <Button onClick={this.show}><p>Borrar Ph  <Icon name='delete'/></p></Button>
          <Button onClick={this.createNewAssembly}><p>Nueva Asamblea <Icon name='plus circle'/></p></Button>
          <Divider />
          <Accordion fluid styled>
            <Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleClick}>
              <Icon name='dropdown' />
              Nit
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <h4> Nit: {this.props.id}</h4>
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 1} index={1} onClick={this.handleClick}>
              <Icon name='dropdown' />
              Direccion
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
              <h4> Direccion: {this.state.info.address}</h4>
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 2} index={2} onClick={this.handleClick}>
              <Icon name='dropdown' />
              Empresa administradora
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 2}>
              <h4>
                {this.state.info.managementCompany}
              </h4>
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 3} index={3} onClick={this.handleClick}>
              <Icon name='dropdown' />
              Telefono empresa administradora
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 3}>
              <h4>
                Tel: {this.state.info.managementCompanyPhone}
              </h4>
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 4} index={4} onClick={this.handleClick}>
              <Icon name='dropdown' />
              Contacto empresa administradora
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 4}>
              <h4>
                Nombre: {this.state.info.managementCompanyContact}
              </h4>
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 5} index={5} onClick={this.handleClick}>
              <Icon name='dropdown' />
              Telefono contacto empresa administradora
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 5}>
              <h4>
                Tel: {this.state.info.managementCompanyContactPhone}
              </h4>
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 6} index={6} onClick={this.handleClick}>
              <Icon name='dropdown' />
              Numero maximo de representaciones
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 6}>
              <h4>
                {this.state.info.maxRepresentations}
              </h4>
            </Accordion.Content>
          </Accordion>
          <Divider/>
          <h2>Asambleas de la copropiedad</h2>
          <Divider />
          <CardGroup>
            {this.state.listaAssemblies ?
              this.state.listaAssemblies.map(asm => {
                return (
                  <CardAssembly
                    key={asm.id}
                    idAssembly= {asm.id}
                    date={asm.data.date}
                    place={asm.data.place}
                    onClick={()=>{this.props.onClick(asm.id);}}
                  />
                );
              }) : <div></div>
            }
          </CardGroup>
          <EditPh
            open={this.state.openPhEdit}
            onClose={this.onClosephEdit}
            idPh={this.props.id}
            info={this.state.info}
            refrescar={this.getInfoPh}
          />
          <Confirm
            open={this.state.openConfirm}
            content='Esta seguro de eliminar el Ph'
            onCancel={this.handleCancel}
            onConfirm={this.deletePh}
          />
          <NewAssembly
            open={this.state.openCreateAssembly}
            onClose={this.onCloseCreateAssembly}
            idPh={this.props.id}
            refrescar={this.getAssembliesxId}
          />
        </Container>
      );
    //}
  }
}

export default PhDetail;