import React, { Component } from 'react';
import { Button, Form, Modal, Container} from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { FIREBASE_AUTH } from '../../constants';

class EditAdmin extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      passwordConfirm:'',
    };
  } 

  componentDidMount(){
      this.setState({email:localStorage.getItem('usuario')})
  }
  
  handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
  }

  handleEditAdmin = () => {

    const {password, passwordConfirm} = this.state;
    
    if(password !== passwordConfirm){
        toast.error('Los passwords no coindicen, verifiquelos nuevamente',
            {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
    }else{
        FIREBASE_AUTH.onAuthStateChanged((user)=> {
            if (user) {
                const pwsRegEx = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\.\,])(?=.{8,})");
                this.setState({email:user.email});
                if(pwsRegEx.test(password)){
                 user.updatePassword(password).then(()=> {
                    // Update successful.
                        toast.info('El password fue actualizado correctamente.',
                        {
                            position: 'top-center',
                            autoClose: 10000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true
                        });
                        window.location.reload();                        
                    }).catch(function(error) {
                        // An error happene.
                        console.log("Error", error); 
                    });
                }else{
                toast.error("El password no cumple no los requisitos minimos")
                }
                
            } else {
                console.log('No hay usuario')
                // No user is signed in.
            }
        })
    }       

  }

  render() {
    const campos = [
      { name: 'user', label: 'Usuario', type: 'text', value: this.state.email },
      { name: 'password', label: 'Password', type: 'password'},
      { name: 'passwordConfirm', label: 'Confirmar password', type: 'password'},
    ];

    return (

        <Modal open={this.props.open}
          onClose={this.props.onClose}
        >
          <Modal.Header>Cambiar Password</Modal.Header>
          <Modal.Content>
            <Container>
              <Form>
                {campos.map(c => {
                  if (c.type === 'text') {
                    return (
                      <Form.Input
                        key={c.name}
                        value={c.value}
                        onChange={this.handleInput}
                        name={c.name}
                        label={c.label}
                        type={c.type}
                      />
                    );
                  }else if (c.type === 'password') {
                    return (
                      <Form.Input
                        key={c.name}
                        value={c.value}
                        onChange={this.handleInput}
                        name={c.name}
                        label={c.label}
                        type={c.type}
                      />
                    );
                  }
                })}
              </Form>
            </Container>
          </Modal.Content>
          <Modal.Actions>
            <Button primary
              content='Cambiar password'
              icon='save'
              onClick={this.handleEditAdmin}
            />
          </Modal.Actions>
        </Modal>
    );
  }
}

export default EditAdmin;
