import React, { Component } from 'react';
import voTowerLogo from '../Images/SVG-VoTOWER.svg';
import TowerLogo from '../Images/SVG-TOWER-white-logo.svg';
import PropTypes from 'prop-types';
import UserScreen from './UserScreen/UserScreen'
import {
  Button,
  Container,
  Menu,
  Responsive,
  Segment,
  Visibility,
  Image,
} from 'semantic-ui-react';
//import HomepageHeading from './HomepageHeading';
import { FIREBASE_AUTH } from '../constants';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { deleteSession } from './utils/deleteSession';
import EditAdmin from './Login/EditAdmin';


var conf = require('../conf');



class DesktopContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usuario: localStorage.getItem('usuario'),
      token: localStorage.getItem('JWToken'),
      modalLoginToken: false,
      modalLogin: false,
      modalPh: false,
      showLogout: false,
      loguotInProcess: false,
      modalChangePass:false,
    };
  }

  verificarStorage = () => {
    if (
      localStorage.getItem('usuario') &&
      localStorage.getItem('JWToken')
    ) {
      this.setState(
        {
          usuario: localStorage.getItem('usuario'),
          token: localStorage.getItem('JWToken'),
          sesionIniciada: true
        },
        () => {
          window.location = window.location.origin;
          this.props.setAdmin(localStorage.getItem('usuario'));
          //window.location.reload()
        }
      );
    }
  }

  cerrarSesion = () => {
    FIREBASE_AUTH.signOut().then(() =>{
      localStorage.removeItem('usuario');
      localStorage.removeItem('JWToken');
      localStorage.removeItem('session');
      this.setState(
        {
          usuario: null,
          token: null,
          sesionIniciada: false
        },
      );
      window.location.reload()
    }).catch(function(error) {
      console.log("Error logout", error)
    });
  }

  cerrarSesionUsuario = () =>{
    
    const url = `${conf.baseURL}/login/out`;
    this.setState({
      loguotInProcess:true
    });

    Axios.post(url, null, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}} )
    .then(res =>{
      deleteSession();
    })
    .catch(error => {
      try {
        const resData  = error.request; 
        const message = JSON.parse(resData.response)["message"];
        console.error(message);
        toast.error(`${message}`,{
          position: 'top-center',
          autoClose: 10000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        if(resData.status === 401){
          deleteSession();
        }
      } catch (error) {
        console.error(error);
        toast.error(`Se ha porducido un error, intente de nuevo`,{
          position: 'top-center',
          autoClose: 10000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        
      }
      this.setState({
        loguotInProcess:false
      });
    });

  }

  showLogoutEvent = ()=>{
    this.setState({
      showLogout:true
    });
  }

  handleChangePass = () => {
    this.setState({ modalChangePass: true });
  }

  handleLoginToken = () => {
    this.setState({ modalLoginToken: true });
  }
  
  handlePh = () => {
    this.setState({ modalPh: true });
  }

  handleCloseLogin = () => this.setState({ modalLogin: false })
  handleClosePh = () => this.setState({ modalPh: false })
  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: true })
  closeChangePass = () => {
    this.setState({ modalChangePass:false})
  }

  renderAdmin(){
    const { children } = this.props;
    const { fixed } = this.state;
    const h = this.props.showHome?20:20;
    return (
      <Responsive 
        getWidth={this.getWidth} 
        minWidth={Responsive.onlyMobile.minWidth}
      >
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            inverted
            textAlign='center'
            style={{ minHeight: h, padding: '1em 0em'}}
            vertical
          >
            
            <Menu
              fixed={fixed ? 'top' : null}
              inverted={fixed}
              pointing={!fixed}
              secondary={!fixed}
              color='purple'
              size='small'
            > 
              <Menu.Item position='left'>
                <Image
                    src={voTowerLogo}
                    as='a'
                    size='medium'
                    href={window.location.origin}
                    target='_blank'
                />
              </Menu.Item>
              <Container>
                {this.state.usuario ?
                  <Menu.Item>
                    <Button
                      content={`Hola admin: ${this.state.usuario}`}
                      as='' inverted={!fixed}
                    />
                  </Menu.Item>:<div></div>}
              </Container>
              <Container>
                {this.state.usuario ?
                  <Menu.Item position='right'>               
                    <Button
                      content='Cerrar sesión'
                      onClick={this.cerrarSesion}
                      as='a' inverted={!fixed}
                    />
                    <Button
                      content='Cambiar password'
                      onClick={this.handleChangePass}
                      as='a' inverted={!fixed}
                    />
                  </Menu.Item> :
                  <div></div>}
                  <Menu.Item position="right">
                    <Image
                      src={TowerLogo}
                      as='a'
                      size='tiny'
                      href='http://www.TOWER.com.co'
                      target='_blank'
                    />
                  </Menu.Item>
              </Container>
            </Menu>
          </Segment>
          <EditAdmin
            open={this.state.modalChangePass}
            onClose={this.closeChangePass}
            relogin={this.login}
          />
        </Visibility>
        {children}
      </Responsive>
    );
  }

  renderUser(){
    const { children } = this.props;
    const { fixed } = this.state;
    const h = this.props.showHome?20:20;

    let logoutButton
    if(this.state.showLogout){
      logoutButton = (
      <Button
        className ="btn-user-primary logout-button"
        size='large'
        onClick={this.cerrarSesionUsuario}
        disabled={this.state.loguotInProcess}
        loading = {this.state.loguotInProcess}>
        Cerrar Sesión
      </Button>
      );
    }
    return (
      <Responsive 
        getWidth={this.getWidth} 
        minWidth={Responsive.onlyMobile.minWidth}
      >
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            inverted
            textAlign='center'
            style={{ minHeight: h, padding: '1em 0em'}}
            vertical
          >
            
            <Menu
              fixed={fixed ? 'top' : null}
              inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              size='small'
            >
              <Container>
                <Menu.Item position='left' className='header-logo'>
                  <Image
                      src={voTowerLogo}
                      as='a'
                      size='small'
                      href={window.location.origin}
                      target='_blank'
                  />
                </Menu.Item>

                <Menu.Item position="right">
                  {logoutButton}
                  <Image
                    src={TowerLogo}
                    as='a'
                    size='mini'
                    href='http://www.TOWER.com.co'
                    target='_blank'
                  />
                </Menu.Item>
              </Container>
            </Menu>
            <Container>
              <UserScreen showLogoutEvent={this.showLogoutEvent}/>
            </Container>
          </Segment>
        </Visibility>
        {children}
      </Responsive>
    );
  }

  // Heads up!
  // We using React Static to prerender our docs with server side rendering, this is a quite simple solution.
  // For more advanced usage please check Responsive docs under the "Usage" section.
  getWidth = () => {
    const isSSR = typeof window === 'undefined';
    return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
  }

  render() {
    if(this.state.usuario || this.props.url_admin){
      return this.renderAdmin();
    }else{
      return this.renderUser();
    }
  }



}

DesktopContainer.propTypes = {
  children: PropTypes.node,
};

export default DesktopContainer;
