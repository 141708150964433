import React, { Component } from 'react';
import { 
  Container, 
  Button, 
  CardGroup,
  Divider, 
  Confirm, 
  Table, TableHeader, TableRow, TableHeaderCell, TableCell, 
  Icon, 
  TableBody,
  Segment} from 'semantic-ui-react';
import { toast } from 'react-toastify';
import Axios from 'axios';
import CardQuestion from '../Questions/CardQuestion.js';
import EditAsm from './EditAssembly.js';
import NewQuestion from '../Questions/NewQuestion.js'
import ConfigQuestion from '../Questions/ConfigQuestion.js';
import EditQuestion from '../Questions/EditQuestion.js'
import UploadStates from '../States/UploadStates.js'
import EditStates from '../States/EditStates.js';
import NewEstate from '../States/NewEstate.js'
import Representations from '../States/Representations.js';
import Results from '../UserScreen/Results.js';
import Reports from '../Reports/Reports.js' 
var conf = require('../../conf');

class AsmDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      estateList: [],
      questionList:[],
      estateDelete: false,
      openCreateEstate: false,
      openCreateQuestion: false,
      openAsmEdit: false,
      openConfigQuestion:false,
      openEditQuestion: false,
      EditEstate: false,
      idPh: this.props.id,
      info: {},
      openConfirm: false,
      activeIndex: 0,
      resLim:0,
      ansNum:0,
      create: false,
      viewEstates: false,
      modalRepresentation:false,
      quorumState: true,
      consolidated:0,
      openCreateReport:false,
      openViewResults:false,
      rtStatus:false,
      rtActive:false,
      showQuorum:false,
      asmStatus:'',
      date:'',
      maxAnswers:0
    };
    this.consolidate=null;
    this.consolidateRT=null;
    this.timeOut= null;
    this.createReport=this.createReport.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.info !== nextProps.info) {
      this.setState({
        date: nextProps.info.data.date,
        asmStatus: nextProps.info.data.status
      });
    }
  }

  show = () => this.setState({ openConfirm: true })
  handleCancel = () => this.setState({ openConfirm: false })

  componentDidMount() {
    if (!this.props.info) {
      this.getAssembliesxId();
    }
    else {
      this.setState({ info: this.props.info });
      //this.getQuestions(); 
    }
    if (this.props.admin) {
      this.getAssembliesxId();
    }
    this.getQuestions(); //para probar read assemblies
    this.setState({
      idAsm:this.props.info.id,
      asmStatus:this.props.info.data.status,
      date: this.props.info.data.date,
    })
  }

  //Funciones de asambleas

  getAssembliesxId = () => {
    Axios.get(`${conf.baseURL}/assembly?PHid=${this.props.id}`, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
        this.setState({ info: res.data.message });
      }).catch(err => console.log(err));
  }
 
  asmEdit = () => {
    this.setState({ openAsmEdit: true });
  }

  deleteAsm = () => {
    let formData={
      'PHid':this.props.id,
      'id': this.state.idAsm
    }
    Axios.delete(`${conf.baseURL}/assembly/`, {data:formData, headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
        //window.location = window.location.origin;
        this.props.onCloseAsm();
        this.props.refrescar(this.props.id)
      }).catch(err => console.log(err));
    this.handleCancel();
  }

  onCloseAsmEdit = () => {
    this.setState({
      openAsmEdit: false
    });
  }

  activeAsm =()=>{
    let formData={
      'PHid':this.props.id,
      'assemblyId': this.state.idAsm
    }
    Axios.post(`${conf.baseURL}/screen_status/active_assembly`, formData,{headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
        if(!res.error){
          toast.info('La asamblea se ha activado.',
            {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
            //this.setState({asmStatus:'activa'})
            this.props.refrescarAsm(this.state.idAsm);
        }
      }).catch(err => console.log(err));    
  }

  endAsm = () => {
    let formData={
      'PHid':this.props.id,
      'assemblyId': this.state.idAsm
    }
    Axios.post(`${conf.baseURL}/screen_status/finish_assembly`, formData,{headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
        if(!res.error){
          toast.info('La asamblea ha finalizado.',
            {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
            this.mainScreen();
            this.props.refrescarAsm(this.state.idAsm);
        }
      }).catch(err => console.log(err));
      this.props.refrescarAsm(this.state.idAsm);
  }

  //Funciones de preguntas
  getQuestions = () => {
    this.setState({questionList:[], viewEstates:false})  
    Axios.get(`${conf.baseURL}/question?PHid=${this.props.id}&assemblyId=${this.props.info.id}`, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
        let datos = res.data.message;
        this.setState({ questionList: datos });
        //this.render();
      }).catch(err => console.log(err));
  }

  configQuestion = () => {
    this.setState({action:true})
    this.setState({ 
      openConfigQuestion: true,
    });
  }

  configQuestionEdit = (idQuestion) => {
    this.setState({ action: false, idQuestion: idQuestion});
    this.setState({ openConfigQuestion: true });
  }

  questionEdit = (ansNum,resLim) => {
    this.setState({ 
      openConfigQuestion:false,
      ansNum:ansNum,
      resLim: resLim
    });
    this.setState({ openEditQuestion: true })
  }

  createNewQuestion = (ansNum, resLim) => {
    this.setState({ 
      openConfigQuestion:false,
      ansNum:ansNum,
      resLim: resLim
    });
    this.setState({ openCreateQuestion: true })
  }

  onCloseConfigQuestion = () => {
    this.setState({
      openConfigQuestion: false
    });
  }

  onCloseCreateQuestion = () => {
    this.setState({
      openCreateQuestion: false
    });
  }

  onCloseEditQuestion = () => {
    this.setState({
      openEditQuestion: false
    });
  }
  
  modifyQuestionStatus = (status,idQuestion,time)=>{

    let formData ={
      'PHid': this.props.id,
      'assemblyId': this.state.idAsm,
      'questionId':idQuestion,
    }
    if(status==='activate'){
      if(this.state.asmStatus==='activa'){
        Axios.post(`${conf.baseURL}/screen_status/activate_question`,formData, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
        .then(res => {
          if(time !== '0'){
            localStorage.setItem('timer',Date.now());
            this.timeOut = setTimeout(()=>{
              this.finishQuestion(idQuestion);
            },(time*1000)) 
          }
          this.getQuestions();
        }).catch(err => console.log(err));
      }else{
        toast.error('La asamblea no esta activa. por favor activela',
          {
            position: 'top-center',
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
      }
    }else if(status==='cancel'){
      Axios.post(`${conf.baseURL}/screen_status/cancel_question`,formData, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
        clearInterval(this.timeOut);
        this.getQuestions();
      }).catch(err => console.log(err));
    }else if(status==='finish'){
      /*Axios.get(`${conf.baseURL}/votation?PHid=${this.state.idPh}&assemblyId=${this.state.idAsm}&questionId=${this.props.idQuestion}`,{headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
        console.log("Votacion consolidada");
      }).catch(err => console.log(err));*/
      this.consolidateResults(idQuestion);
      Axios.post(`${conf.baseURL}/screen_status/deactivate_question`,formData, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
        console.log(res);
        this.getQuestions();
      }).catch(err => console.log(err));
    }
  }

  
  finishQuestion = (idQuestion) =>{
    this.modifyQuestionStatus("finish",idQuestion,0);
    localStorage.removeItem('timer');
  }

  //Funciones de inmuebles

  getEstatesxId = () => {
    Axios.get(`${conf.baseURL}/estate?PHid=${this.props.id}&assemblyId=${this.props.info.id}`, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
        this.setState({ estateList: res.data.message });
      }).catch(err => console.log(err));
    this.setState({viewEstates:true})
  }

  onCloseCreateEstate = () => {
    this.setState({
      openCreateEstate: false
    });
  }

  onCloseEditEstate = () => {
    this.setState({EditEstate:false
    });
  }

  estateEdit(infoEstate){
    this.setState({infoEstate:infoEstate});
    this.setState({EditEstate:true});
  }

  estateDelete(id){
    let formData={
      'id': id,
      'PHid': this.state.idPh,
      'assemblyId': this.state.idAsm
    }
    Axios.delete(`${conf.baseURL}/estate`, {data:formData, headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
        if(!res.error){
          toast.info('El Inmueble se ha borrado.',
            {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
            this.getEstatesxId();
        }else{
          toast.error('Algo salio mal. Intentalo nuevamente',
            {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
        }
      }).catch(err => console.log(err));
    this.setState({viewEstates:true})
  }

  createNewEstate = () => {
    this.setState({ openCreateEstate: true });
  }

  createEstatesBatch= () => {
    this.setState({
      createEstatesBatch:true
    })
  }

  onCloseCreateEstatesBatch = () => {
    this.setState({
      createEstatesBatch: false
    });
  }

  //Funciones de representacion

  assignRepresentation = ()=>{
    this.setState({modalRepresentation :true})
  }
  
  closeRepresentation = ()=>{
    this.setState({modalRepresentation :false})
  }

  //Quorum

  askQuorum = (quorumStatus) =>{
    this.setState({quorumState :!quorumStatus});
    let formData={
      'PHid': this.state.idPh,
      'assemblyId': this.state.idAsm
    }
    if(quorumStatus === true){
      Axios.post(`${conf.baseURL}/quorum/ask_quorum`, formData, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(() => {
        this.consolidate = setInterval(()=>{
          this.consolidateQuorum();
        },1500)
      }).catch(err => console.log(err));
    }else{
      clearInterval(this.consolidate);
    }
  }

  consolidateQuorum =() =>{
    
    Axios.get(`${conf.baseURL}/quorum/report_quorum?PHid=${this.state.idPh}&assemblyId=${this.state.idAsm}`, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
    .then(res => {
      if(!res.error){
        this.setState({consolidated:res.data.message.value});
      }else{
        toast.error('Algo salio mal. Intentalo nuevamente',
          {
            position: 'top-center',
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
      }
    })
  }

  showQuorum =() =>{
    this.setState(st=>{
      st.showQuorum=true;
    })
    let formData={
      'PHid': this.state.idPh,
      'assemblyId': this.state.idAsm
    }
    Axios.post(`${conf.baseURL}/screen_status/show_quorum`, formData, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
      }).catch(err => console.log(err));
  }

  hideQuorum =() =>{
    this.setState(st=>{
      st.showQuorum=false;
    })
    this.mainScreen();
  }

  //Results
  openViewResults = (rtStatus,questionId, maxAnswers)=>{
    this.setState(st=>{
      st.rtStatus=rtStatus;
    });
    if(rtStatus ===true){
      this.consolidateRT = setInterval(()=>{
        this.consolidateResults(questionId);
      },1000);
      this.setState({openViewResults:true, questionId:questionId, maxAnswers: maxAnswers});
    }else{
      clearInterval(this.consolidateRT);
      this.consolidateResults(questionId);
      this.setState({openViewResults:true, questionId:questionId, maxAnswers: maxAnswers});
    }
  }
    
  closeViewResults = ()=>{
    this.setState({openViewResults:false , viewEstates:false});
    clearInterval(this.consolidateRT);
  }

  consolidateResults = (questionId) =>{
    Axios.get(`${conf.baseURL}/votation?PHid=${this.state.idPh}&assemblyId=${this.state.idAsm}&questionId=${questionId}`,{headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
    .then(res => {
      if(res.error){
        toast.error('Algo salio mal. Intentalo nuevamente',
          {
            position: 'top-center',
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
      } 
    })
  }

  showRTResults =()=>{
    this.setState({rtActive:true})
    let formData={
      'PHid':this.props.id,
      'assemblyId': this.state.idAsm
    }
    Axios.post(`${conf.baseURL}/screen_status/show_results`, formData,{headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
        if(!res.error){
          toast.info('Mostrando resultados.',
            {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
        }
      }).catch(err => console.log(err));
    
  }

  hideRTResults =()=>{
    this.setState({rtActive:false})
    let formData={
      'PHid':this.props.id,
      'assemblyId': this.state.idAsm
    }
    Axios.post(`${conf.baseURL}/screen_status/hide_results`, formData,{headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
        if(!res.error){
          toast.info('Los resultados en tiempo real se han ocultado.',
            {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
        }
      }).catch(err => console.log(err));
    
  }

  
  //Reportes
  createReport = () =>{
    this.setState({openCreateReport:true});
  }

  onCloseCreateReport =()=>{
    this.setState({openCreateReport:false});
  }
  //Renderizado 

  mainScreen =() =>{
    let formData={
      'PHid': this.state.idPh,
      'assemblyId': this.state.idAsm
    }
    Axios.post(`${conf.baseURL}/screen_status/show_main_screen`, formData, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
    .then(res => {
    }).catch(err => console.log(err));
  }

  render() {
    if (!this.state.openViewResults){
      if(!this.state.viewEstates){
        return (
          <Container>
            <h1> Asamblea {this.state.date}</h1>
            <h2> Quorum Actual: {this.state.consolidated} % </h2>
            <Divider />
              <Button onClick={this.props.onCloseAsm} ><Icon name='arrow left' />  Ver Ph</Button>
            <Divider/>
            <h2>Gestion Asambleas</h2>
              <Button onClick={this.asmEdit}><p>Editar Asamblea  <Icon name='edit'/></p></Button>
              <Button onClick={this.show}><p>Borrar Asamblea <Icon name='delete'/></p> </Button>
              {this.state.asmStatus==='activa'?
                <Button onClick={this.endAsm}><p>Finalizar Asamblea <Icon name='stop'/></p></Button>:
                this.state.asmStatus==="desactivada"?
                <Button onClick={this.activeAsm}><p>Activar Asamblea <Icon name='play'/></p></Button>: null
              }              
              <Button onClick={this.getEstatesxId}><p> Inmuebles <Icon name='building'/></p></Button>
              <Button onClick={this.configQuestion}><p> Nueva Pregunta <Icon name='plus circle'/></p></Button>
            <Divider/>
            {this.state.asmStatus==='activa'?
              this.state.quorumState===false?
               <Button onClick={()=>{this.askQuorum(false);}}><p>Parar Quorum <Icon name='users'/></p></Button>:
               <Button onClick={()=>{this.askQuorum(true);}}><p> Solicitar Quorum <Icon name='users'/></p></Button>:<p></p>
            }
            {this.state.asmStatus==='activa'?
              this.state.showQuorum?
                <Button onClick={()=> {this.setState({showQuorum:false});this.hideQuorum();}}><p>Ocultar Quorum <Icon name='hide'/></p></Button>:
                <Button onClick={()=> {this.setState({showQuorum:true});this.showQuorum()}}><p>Mostrar Quorum <Icon name='unhide'/></p></Button>:<div></div>
            }
            <Button onClick={this.mainScreen}><p>Mostrar user home <Icon name='home'/></p></Button>
            {this.state.asmStatus==='finalizada'?
                <Button onClick={()=>{this.createReport();}}><p>Generar Reportes <Icon name='chart pie'/></p></Button>:<div></div>
            }
            <Divider />
            <h2>Preguntas de la Asamblea</h2>
            <Divider />
            <CardGroup>
              {this.state.questionList ?
                this.state.questionList.map((ques)=> {
                  return (
                    <CardQuestion
                      key={ques.id}
                      idQuestion= {ques.id}
                      info={ques}
                      idAsm={this.state.idAsm}
                      idPh={this.props.id}
                      edit={this.configQuestionEdit}
                      refrescar={this.getQuestions}
                      viewResults={this.openViewResults}
                      current={localStorage.getItem('timer')}
                      questionStatus={this.modifyQuestionStatus}
                    />
                  )
                }):<div></div>
              }
            </CardGroup>
            <EditAsm
              open={this.state.openAsmEdit}
              onClose={this.onCloseAsmEdit}
              idPh={this.props.id}
              info={this.state.info}
              refrescar={this.props.refrescarAsm}
            />
            <Confirm
              open={this.state.openConfirm}
              content='Esta seguro de eliminar la asamblea ?'
              onCancel={this.handleCancel}
              onConfirm={this.deleteAsm}
            />
            <ConfigQuestion
              open={this.state.openConfigQuestion}
              onClose={this.onCloseConfigQuestion}
              action={this.state.action}
              onCreate={this.createNewQuestion}
              onEdit={this.questionEdit}
            />
            <NewQuestion
              open={this.state.openCreateQuestion}
              onClose={this.onCloseCreateQuestion}
              ansNum= {this.state.ansNum}
              resLim= {this.state.resLim}
              idPh={this.props.id}
              idAsm={this.state.idAsm}
              refrescar={this.getQuestions}
            />
            <EditQuestion
              open={this.state.openEditQuestion}
              onClose={this.onCloseEditQuestion}
              ansNum= {this.state.ansNum}
              resLim= {this.state.resLim}
              idQuestion={this.state.idQuestion}
              info={this.state.questionList}
              idPh={this.props.id}
              idAsm={this.state.idAsm}
              refrescar={this.getQuestions}
            />
            <Reports
              open={this.state.openCreateReport}
              onClose={this.onCloseCreateReport}
              idPh={this.props.id}
              idAsm={this.state.idAsm}
            />            
          </Container>
        );
      }else{
        return (
          <Container>
            <h1> Inmuebles de la asamblea {this.props.info.data.date}</h1>
            <Divider />
            <Button onClick={this.getQuestions} ><p><Icon name='arrow left' /> Ver Asamblea</p></Button>
            <Button onClick={this.createNewEstate}><p> Nuevo Inmueble <Icon name='plus circle'/></p></Button>
            <Button onClick={this.createEstatesBatch}><p> Cargar Inmuebles <Icon name='upload'/></p></Button>
            <Button onClick={this.assignRepresentation}><p> Representaciones <Icon name='handshake'/></p></Button>
            <Table size='small'>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell> PHid </TableHeaderCell>
                  <TableHeaderCell> idInmueble </TableHeaderCell>
                  <TableHeaderCell> Nombre Propietario </TableHeaderCell>
                  <TableHeaderCell> Coeficiente </TableHeaderCell>
                  <TableHeaderCell> Coeficiente representado</TableHeaderCell>
                  <TableHeaderCell> Representado por</TableHeaderCell>
                  <TableHeaderCell> Representando a</TableHeaderCell>
                  <TableHeaderCell> Email </TableHeaderCell>
                  <TableHeaderCell> Token </TableHeaderCell>
                  <TableHeaderCell> Editar </TableHeaderCell>
                  <TableHeaderCell> Borrar </TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {this.state.estateList?
                  this.state.estateList.map(st=>{
                    return(
                      <TableRow key={st.id}>
                        <TableCell>{this.state.idPh}</TableCell>
                        <TableCell>{st.id}</TableCell>
                        <TableCell>{st.data.ownerName}</TableCell>
                        <TableCell>{st.data.coefficient}</TableCell>
                        <TableCell>{st.data.representationCoefficient? (st.data.representationCoefficient.toFixed(3)*1):0}</TableCell>
                        <TableCell>{st.data.representedBy? st.data.representedBy:''}</TableCell>
                        <TableCell>
                          {
                            st.data.representations? 
                              st.data.representations.map((rp,ind)=>{
                                let sep='';
                                if(ind===(st.data.representations.length-1)){
                                  sep='\n';
                                }else{
                                  sep=', \n';
                                }
                                return (rp+sep)
                              }):''
                          }
                        </TableCell>
                        <TableCell>{st.data.email}</TableCell>
                        <TableCell>{st.data.accessToken}</TableCell>
                        <Table.Cell onClick={()=>{this.estateEdit(st)}}><Icon name='edit'/></Table.Cell>
                        <Table.Cell onClick={()=>{this.estateDelete(st.id)}}><Icon name='delete'/></Table.Cell>
                      </TableRow>
                    );
                  }):<div></div>
                }
              </TableBody>
            </Table>
            <EditStates
              open={this.state.EditEstate}
              onClose={()=>{this.onCloseEditEstate()}}
              idPh={this.state.idPh}
              idAsm={this.state.idAsm}
              info={this.state.infoEstate}
              refrescar={this.getEstatesxId}
            />
            <UploadStates
              open={this.state.createEstatesBatch}
              onClose={this.onCloseCreateEstatesBatch}
              idAsm={this.state.idAsm}
              idPh={this.props.id}
              refrescar={this.getEstatesxId}
            />
            <NewEstate
              open={this.state.openCreateEstate}
              onClose={this.onCloseCreateEstate}
              idAsm={this.state.idAsm}
              idPh={this.props.id}
              refrescar={this.getEstatesxId}
            />
            <Representations
              open={this.state.modalRepresentation}
              onClose={this.closeRepresentation}
              info={this.state.estateList}
              idPh={this.props.id}
              idAsm={this.state.idAsm}
              refrescar={this.getEstatesxId}
            />

          </Container>
        );
      }
    }else{
      return(
        <Segment textAlign='center'>
          <Container>
            <h1> Resultados de la pregunta</h1>
            <Divider />
            <Button onClick={this.closeViewResults} ><Icon name='arrow left' />  Ver preguntas</Button>
            {this.state.rtStatus?
              this.state.rtActive?
              <Button onClick={this.hideRTResults}><p> Ocultar resultados en tiempo real <Icon name='hide'/></p></Button>:
              <Button onClick={this.showRTResults}><p> Mostrar resultados en tiempo real <Icon name='unhide'/></p></Button>:
              null//<Button onClick={this.showRTResults}><p> Mostrar resultados <Icon name='unhide'/></p></Button>
            }
          </Container>
          <Container>
            <Results 
              className={'result-admin'}
              PHid={this.props.id}
              assemblyId={this.state.idAsm}
              questionId={this.state.questionId}
              showQuestion={true}
              maxAnswers = {this.state.maxAnswers}
            />
          </Container>
        </Segment>
        );
    }
  }
}

export default AsmDetail;