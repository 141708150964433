import React, { Component } from 'react';
import axios from 'axios';
import { Button, Form, Modal, Container} from 'semantic-ui-react';
import { toast } from 'react-toastify';


var conf = require('../../conf');

class UploadStates extends Component {

  constructor(props) {
    super(props);
    this.state = {
      stateList: [],
      stateDelete: false,
      openCreateState: false,
      data: [],
      openConfirm: false,
      activeIndex: 0,
      resLim:0,
      ansNum:0,
      create: false,
    };
    this.updateData = this.updateData.bind(this);
  }

  show = () => this.setState({ openConfirm: true })
  handleCancel = () => this.setState({ openConfirm: false })

  componentDidMount() {
   this.setState({
       idPh:this.props.idPh,
       idAsm: this.props.idAsm,
   })
  }

  handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value});
  }

  onDrop = (e) => {
    
    var Papa = require("papaparse/papaparse.min.js");
    Papa.parse(e.target.files[0], {
      header: true,
      download: true,
      skipEmptyLines: true,
      // Here this is also available. So we can call our custom class method
      complete: this.updateData
    });

    this.setState({ file: e.target.files[0] });
  }

  updateData(result) {
    const data = result.data;
    // Here this is available and we can call this.setState (since it's binded in the constructor)
    this.setState({data: data}); // or shorter ES syntax: this.setState({ data });

    let dataFile=this.state.data.map(df=>{
      return df;
    });
    let dataToSave=[]
    for(let i=0;i<dataFile.length;i++){
      dataToSave[i]={
        'id': dataFile[i].estateId,
        'ownerName':dataFile[i].ownerName, 
        'coefficient':(dataFile[i].ownerCoefficient).replace(",",".")*1, 
        //'representationCoefficient':dataFile[i].representedCoefficient, 
        'email': dataFile[i].email,
        'accessToken':dataFile[i].accessToken
      }
    }
    this.setState({dataFile:dataToSave});
  }

  handleSave = () => {
    
    let formData = {
        'assemblyId': this.props.idAsm, 
        'PHid':this.props.idPh,
        'data':this.state.dataFile
    }
    axios.post(`${conf.baseURL}/batch_estate`, formData, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
        let fail = res.data.error;
        if (fail) {
          //alert('Intentelo nuevamente');
          toast.error(`${res.data.message}`,
            {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
            this.props.onClose();
        }
        else {
          toast.info('Los inmuebles se cargaron correctamente',
            {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
          this.props.onClose();
          this.props.refrescar();
        }
      })
      .catch(err => {
        console.log(err);
        toast.error('Algo salio mal. Intentalo nuevamente',
          {
            position: 'top-center',
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
      });
  }

 
  render() {
    const campos = [
        { name: 'file', label: 'Cargar archivo (csv)', type: 'file'},

      ];
      return (
        <React.Fragment>
  
          <Modal open={this.props.open}
            onClose={this.props.onClose}
          >
            <Modal.Header>Cargar archivo inmuebles</Modal.Header>
            <Modal.Content>
              <Container>
                <Form>
                  {campos.map(c => {
                    if (c.type === 'file') {
                      return (
                        <Form.Input
                          key={c.name}
                          value={this.state.c}
                          onChange={this.onDrop}
                          name={c.name}
                          label={c.label}
                          type={c.type}
                        />
                      );
                    }
                  })}
                </Form>
              </Container>
            </Modal.Content>
            <Modal.Actions>
              <Button primary
                content='Cargar inmuebles'
                icon='save'
                onClick={()=>{
                  this.handleSave()
                }}
              />
            </Modal.Actions>
  
          </Modal>
        </React.Fragment>
      );  
  }
}

export default UploadStates;