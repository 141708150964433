import React, { Component } from 'react';
import { Button, Form, Modal} from 'semantic-ui-react';
import axios from 'axios';
import { toast } from 'react-toastify';
var conf = require('../../conf');

class EditStates extends Component {

  constructor(props) {
    super(props);

    this.state = {
      PHid:'',
      idState:'',
      ownerName:'',
      ownerCoefficient:'',
      representedCoefficient:'',
      email:'',
      token:'',
    };
  }

  componentDidMount (){
    
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.info !== nextProps.info) {
      this.setState({
        idState: nextProps.info.id,
        ownerName: nextProps.info.data.ownerName,
        ownerCoefficient: nextProps.info.data.coefficient,
        representedCoefficient: nextProps.info.data.representationCoefficient,
        token: nextProps.info.data.accessToken,
        email: nextProps.info.data.email,
      });
    }
  }


  handleSave = () => {
    let coef;
    try {
      coef = Number((this.state.ownerCoefficient).replace(",",".")*1);
    } catch (error) {
      coef = this.state.ownerCoefficient
    }
    let formData={
      'id': this.state.idState,
      'ownerName':this.state.ownerName,
      'coefficient': coef,
      'email': this.state.email,
      'PHid': this.props.idPh,
      'assemblyId': this.props.idAsm
    };
    console.log("Coeficiente a guardar - edit:", formData.coefficient);
    axios.put(`${conf.baseURL}/estate`, formData, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
        let fail = res.error;
        if (fail) {
          toast.error('Algo salio mal. Intentalo nuevamente',
            {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
        }
        else {
          toast.info('El Inmbueble ha sido actualizado.',
            {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
          this.props.onClose(); 
          this.props.refrescar();
        }
      })
  }

  handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
  }

  render() {
    const campos = [
      { name: 'idState', label: 'Id del inmueble', type: 'text' ,value:this.state.idState},
      { name: 'ownerName', label: 'Nombre del copropietario:', type: 'text' ,value:this.state.ownerName},
      { name: 'ownerCoefficient', label: 'Coeficiente de copropiedad:', type:'text',value:this.state.ownerCoefficient},
      { name: 'representedCoefficient', label: 'Coeficiente representado:', type: 'text', value:this.state.representedCoefficient},
      { name: 'email', label: 'Email:', type: 'text',value:this.state.email },
      { name: 'token', label: 'Token de asamblea:', type: 'text',value:this.state.token },
    ];

    return (
      <Modal
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <Modal.Header>Editar Inmueble {this.state.idState}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form>
              {campos.map(c => {
                  if(c.name ==='idState'){
                    return (
                      <Form.Input
                        key={c.name}
                        value={this.state.idState}
                        name={c.name}
                        label={c.label}
                        type={c.type}
                      />
                    );
                  }else if(c.name ==='token' || c.name ==='representedCoefficient'){
                    return (
                      <Form.Input
                        key={c.name}
                        value={c.value}
                        name={c.name}
                        label={c.label}
                        type={c.type}
                      />
                    );
                  }else{
                    return (
                      <Form.Input
                        key={c.name}
                        value={c.value}
                        onChange={this.handleInput}
                        name={c.name}
                        label={c.label}
                        type={c.type}
                      />
                    );
                  }
              })}
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button primary
            content='Editar Inmueble'
            icon='save'
            onClick={this.handleSave}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default EditStates;
