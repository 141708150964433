import React, { Component } from 'react';
import { Button, Form, Modal, Container} from 'semantic-ui-react';
import { DateTimeInput } from 'semantic-ui-calendar-react';
import axios from 'axios';
import { toast } from 'react-toastify';
var conf = require('../../conf');


class NewAssembly extends Component {

  constructor(props) {
    super(props);
    this.state = {
      date: '',
      place:''
    };

  }

  handleSave = () => {
    const {
      date,
      place } = this.state;
    let formData = {
      'PHid':this.props.idPh,
      'date': date,
      'place':place
    }
    axios.post(`${conf.baseURL}/assembly`, formData, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
        let fail = res.data.error;
        if (fail) {
          //alert('Intentelo nuevamente');
          toast.error('Algo salio mal. Intentalo nuevamente',
            {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
        }
        else {
          toast.info('La asamblea se creo correctamente',
            {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
          this.props.onClose();
          this.props.refrescar();
        }
      })
      .catch(err => {
        console.log(err);
        toast.error('Algo salio mal. Intentalo nuevamente',
          {
            position: 'top-center',
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
      });
  }

  handleChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  }

  handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value});
  }

  render() {
    const campos = [
      { name: 'date', label: 'Fecha', type: 'date', value: this.state.date },
      { name: 'place', label: 'Lugar', type: 'text' },
    ];

    return (
      <React.Fragment>

        <Modal open={this.props.open}
          onClose={this.props.onClose}
        >
          <Modal.Header>Crear una asamblea</Modal.Header>
          <Modal.Content>
            <Container>
              <Form>
                {campos.map(c => {
                  if (c.type === 'text') {
                    return (
                      <Form.Input
                        key={c.name}
                        value={this.state.c}
                        onChange={this.handleInput}
                        name={c.name}
                        label={c.label}
                        type={c.type}
                      />
                    );
                  }
                  else if (c.type === 'date') {
                    return (
                      <DateTimeInput
                        key={c.name}
                        name={c.name}
                        label={c.label}
                        value={c.value}
                        iconPosition="left"
                        dateFormat='YYYY/MM/DD Z'
                        onChange={this.handleChange}
                      />
                    );
                  }
                })}
              </Form>
            </Container>
          </Modal.Content>
          <Modal.Actions>
            <Button primary
              content='Crear Asamblea'
              icon='save'
              onClick={this.handleSave}
            />
          </Modal.Actions>

        </Modal>
      </React.Fragment>
    );
  }
}

export default NewAssembly;
