import { logger } from './logger'
import { toast } from 'react-toastify';

export const realTime = (reference, rtFuntion, failMessage, timer) => {

    return reference.onSnapshot(rtFuntion, (error) => {
        const errorMessage = `${failMessage}.${error}`;
        logger(errorMessage, "error");
        toast.error(failMessage);
        clearTimeout(timer);
    })
   
     
}

