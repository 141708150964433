import firebase from 'firebase';
import 'firebase/firestore';

export const LOADING_SCREEN = "loading";
export const MAIN_SCREEN = "main";
export const QUORUM_SCREEN = "quorum";
export const VOTE_SCREEN = "questions";
export const RESULTS_SCREEN = "results";

export const PH_COLLECTION = "PH";
export const ASSEMBLIES_COLLECTION = "ASSEMBLIES";
export const ESTATE_COLLECTION = "ESTATE";
export const TOKEN_COLLECTION = "TOKEN";
export const QUESTIONS_COLLECTION = "QUESTIONS";
export const ANSWER_COLLECTION = "ANSWER";
export const VOTATION_COLLECTION = "VOTATION";
export const VOTATION_RESULT = "RESULT";
export const VOTES_COLLECTION = "VOTES";
export const SCREEN_STATUS_COLLECTION = "QUESTION_STATUS";
export const SCREEN_STATUS_DEFAULT_ID = "default";
export const QUORUM_COLLECTION = "QUORUM";

export const FIREBASE_CONFIG = {
    apiKey: "AIzaSyDK-OHYU8mGQSakyijQrxHqM-BFCaFUt-k",
    authDomain: "vote-tower.firebaseapp.com",
    databaseURL: "https://vote-tower.firebaseio.com",
    projectId: "vote-tower",
    storageBucket: "vote-tower.appspot.com",
    messagingSenderId: "1030418041917",
    appId: "1:1030418041917:web:45f43d41c2a309f67aee54",
    measurementId: "G-93M9XTK5WH"
  };

export const FIREBASEAPP =firebase.initializeApp(FIREBASE_CONFIG);
export const FIREBASE_DB = FIREBASEAPP.firestore();
export const FIREBASE_AUTH = FIREBASEAPP.auth();

export const TIMEOUT = 60*1000;



