import React, { Component } from 'react';
import { Card,Button } from 'semantic-ui-react';

class CardAssembly extends Component {

  render() {
    return (
      <Card>
        <Card.Content>
          <Card.Header>Asamblea {this.props.date}</Card.Header>
          {/*<Card.Description>Fecha: {this.props.date}</Card.Description>*/}
          <Card.Description>Lugar: {this.props.place}</Card.Description>
          <Card.Meta>Id: {this.props.idAssembly}</Card.Meta>
        </Card.Content>
        <Card.Content extra>
          <Button
            content='Ver Asamblea'
            color='purple'
            as='a'
            onClick={() => {this.props.onClick()}}
          />
        </Card.Content>
      </Card>
    );
  }
}

export default CardAssembly;