import React, { Component } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import axios from 'axios';
import { toast } from 'react-toastify';
var conf = require('../../conf');

class NewPH extends Component {

  constructor(props) {
    super(props);

    this.state = {
      namePh:'',
      idPh:'',
      addressPh:'',
      managementCompany:'',
      managementCompanyPhone:'',
      managementCompanyContact:'',
      managementCompanyContactPhone:'',
      maxRepresentations:''
    };


  }

  handleSave = () => {
    const {
      namePh,
      idPh,
      addressPh,
      managementCompany,
      managementCompanyPhone,
      managementCompanyContact,
      managementCompanyContactPhone,
      maxRepresentations } = this.state;

    let formData ={
      'id':idPh,
      'name':namePh,
      'address':addressPh,
      'managementCompany':managementCompany,
      'managementCompanyPhone':managementCompanyPhone,
      'managementCompanyContact':managementCompanyContact,
      'managementCompanyContactPhone':managementCompanyContactPhone,
      'maxRepresentations':maxRepresentations
    }
    axios.post(`${conf.baseURL}/ph`, formData, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}}).then(res => {
      let fail = res.error;
      if (fail) {
        //alert('Intentelo nuevamente');
        toast.error('No se pudo crear la ph, intentelo nuevamente',
          {
            position: 'top-center',
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
      }
      else {
        console.log(fail);
        this.props.onClose();
        window.location.reload()
      }
    }).catch(function (error) {
      if (error.response.status === 500) {
        toast.error('No se pudo crear la PH, intentelo nuevamente',
          {
            position: 'top-center',
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        //alert("No se pudo crear la PH, intentelo nuevamente");
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log('Error: ', error.message);
      }
      console.log(error.config);
    });
  }

  handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
  }

  setDatoFecha=(e)=>{
    this.setState({datoFecha:e});
  }
  handleChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value },()=>{
        if(name === 'fecha_inicio'){
          localStorage.setItem('datoFecha',value);
        }else if(name === 'fecha_fin'){
          if(value<localStorage.getItem('datoFecha')){
            this.setState({fecha_fin:localStorage.getItem('datoFecha')});
          }
        }
      });
    }
  }

  render() {

    const campos = [
      { name: 'idPh', label: 'Nit de la PH', type: 'text' },
      { name: 'namePh', label: 'Nombre de la PH:', type: 'text'},
      { name: 'addressPh', label: 'Dirección PH:', type: 'text'},
      { name: 'managementCompany', label: 'Empresa administradora:', type: 'text' },
      { name: 'managementCompanyPhone', label: 'Telefono empresa administradora:', type: 'text' },
      { name: 'managementCompanyContact', label: 'Contacto empresa administradora:', type: 'text' },
      { name: 'managementCompanyContactPhone', label: 'Telefono contacto empresa administradora:', type: 'text' },
      { name: 'maxRepresentations', label: 'Numero maximo de representaciones:', type: 'text' },
    ];

    return (
      <Modal
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <Modal.Header>Crear una PH</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <Form>
              {campos.map(c => {
                return (
                  <Form.Input
                    key={c.name}
                    value={this.state.c}
                    onChange={this.handleInput}
                    name={c.name}
                    label={c.label}
                    type={c.type}
                  />
                );
              })}
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button primary
            content='Crear PH'
            icon='save'
            onClick={this.handleSave}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default NewPH;
