import React, { Component } from 'react';
import Results from '../Results';
import { Container } from 'semantic-ui-react';
import Loading from './Loading';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { deleteSession } from '../../utils/deleteSession';
import { logger } from '../../utils/logger';

var conf = require('../../../conf');


class ResultsScreen extends Component {

  constructor(props) {
    super(props);

    this.state = {
      maxAnswers: 0
    }
  }

  getQuestions(){
    const url = `${conf.baseURL}/question?PHid=${this.props.PHid}&assemblyId=${this.props.assemblyId}&questionId=${this.props.questionId}`

    Axios.get(url, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
    .then(res => {
    if(!res.data.error){
        const message = res.data.message;  

        const maxAnswers = message.data.multipleAnswer;
        this.setState({
          maxAnswers,
        });
    }
    }).catch(err => {
      const resData  = err.request; 
      const message = JSON.parse(resData.response)["message"];
      console.error(message);
      logger(`Error en ${url}. ${err}. ${message}.`)
      toast.error(`${message}`);
      if(resData.status === 401){
          deleteSession();
      }
    });

  }

  componentDidMount(){
    
    if(this.props.questionId){
      this.getQuestions();

    }
  }

  componentDidUpdate(prevProps, prevState, snapshot){

    if(this.props.questionId){
      if(this.props.questionId !== prevProps.questionId){
        this.getQuestions();
      }
    }
  }


  render() {


    if(this.props.questionId && this.props.PHid && this.props.assemblyId){
      return (
        <Container>
          <p className="main-title">Resultados finales</p>
          <Results className='user-results' PHid={this.props.PHid} assemblyId={this.props.assemblyId} questionId={this.props.questionId} showQuestion={true} maxAnswers={this.state.maxAnswers}/>
        </Container>
      );
    }
    return (<Loading/>);
  }
}

export default ResultsScreen;
