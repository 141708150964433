import React, { Component } from 'react';
import { Container, Image } from 'semantic-ui-react';
import loadingImg from '../../../Images/loadingImg.svg';

class Loading extends Component {

  render() {

    
    return (
        <Container textAlign='center' className="center-vertical">
            <Image src={loadingImg} centered/>
            <p className="main-title">Cargando...</p>
        </Container>
    );
  }
}

export default Loading;
