import React, { Component } from 'react';
import Loading from './StateScreen/Loading';
import { FIREBASE_DB, PH_COLLECTION, ASSEMBLIES_COLLECTION, QUESTIONS_COLLECTION, ANSWER_COLLECTION, QUORUM_COLLECTION } from '../../constants';
import { Container, Grid } from 'semantic-ui-react';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { deleteSession } from '../utils/deleteSession';
import { realTime } from '../utils/realTime';
import { timeOutTimer } from '../utils/timeOutTimer';
import { logger } from '../utils/logger';


var conf = require('../../conf');

class Results extends Component {

    constructor(props) {
        super(props);

        this.state={
            ready:false,
            question: null,
            answers: [],
            actualCuorum: 0
        }

        this.answersListener = null;

    }

    getQuestions(){
        const url = `${conf.baseURL}/question?PHid=${this.props.PHid}&assemblyId=${this.props.assemblyId}&questionId=${this.props.questionId}`
        Axios.get(url, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
        .then(res => {
            if(!res.data.error){
                const message = res.data.message;  
                const question = message.data.question;
                const answers = message.answers.reduce((acumulated, current) => {
                    const id = current.id;
                    acumulated[id] = {
                        text:current.data.text,
                        result: 0
                    }
                    return acumulated;
                }, {});
        
                this.setState({
                    ready: true,
                    question,
                    answers,
                })

                this.setListener();
            }
        }).catch(err => {
            try{
                const resData  = err.request; 
                const message = JSON.parse(resData.response)["message"];
                console.error(message);
                logger(`Error en ${url}. ${err}. ${message}.`)
                toast.error(`${message}`);
                if(resData.status === 401){
                    deleteSession();
                }
            }catch(err2){
                console.error(err);
                console.error(err2);
                logger(`Error en ${url}. ${err2}.`)

                
            }
        });
    
    }

    setListener(){

        const answersDocuments = FIREBASE_DB.collection(PH_COLLECTION).doc(this.props.PHid)
                                    .collection(ASSEMBLIES_COLLECTION).doc(this.props.assemblyId)
                                    .collection(QUESTIONS_COLLECTION).doc(this.props.questionId)
                                    .collection(ANSWER_COLLECTION);
        
        const answersRTFail = "Error al intentar concetar al servidor de Respuestas"
        this.answersTimer = timeOutTimer(`${answersRTFail}0x2`)

        let answersRTFunction = results => {
            
            let answers = this.state.answers;
            const docs = results.docs;

            for(let a = docs.length -1; a >= 0; a--){

                const doc = docs[a];
                const docId = doc.id;
                const docData = doc.data();

                answers[docId].result = docData.result
            }

            this.setState({
                answers
            })

            clearTimeout(this.answersTimer);   
            logger("answers socket ok", "log");
            
        };

        this.answersListener = realTime(answersDocuments, answersRTFunction, `${answersRTFail}0x1`, this.answersTimer);

    }

    componentDidMount(){
        
        this.getQuestions();
        
        const quorumDocument = FIREBASE_DB.collection(PH_COLLECTION).doc(this.props.PHid)
                                    .collection(ASSEMBLIES_COLLECTION).doc(this.props.assemblyId)
                                    .collection(QUORUM_COLLECTION).doc("results");
        

        quorumDocument.get().then(doc => {
            this.setState({actualCuorum: doc.data().value})
        }).catch(error =>{
            console.error(error);
            logger(`Error en obtener resulados por Firebase`)

        })
        
    }

    componentWillUnmount(){
        this.answersListener();
        clearTimeout(this.answersTimer);
    }

    fixNumber(num){
        return (num.toFixed(3))*1
    }
    

    render() {

        let returnedComponet;

        if(this.state.ready){

            let question;
            if(this.props.showQuestion){
                question = (<p className="main-title">{this.state.question}</p>);
            }
            const  answers =  this.state.answers
            const answersId = Object.keys(answers);

            const orderedIds = answersId.reduce((acumulated, current)=>{

                const cantAcumulated = acumulated.length;
                let counter = 0;
                const currentResult = answers[current].result;

                for (counter; counter < cantAcumulated; counter ++){

                    const cicleResutl = answers[acumulated[counter]].result; 

                    if(currentResult > cicleResutl){
                        break;
                    }
                }
                acumulated.splice(counter, 0, current);
                return acumulated


            },[]);

            const renderedAnswers = orderedIds.map(id =>{
                const actualAnswer = answers[id];
                return (
                    <Grid.Row key={id}>
                    <Grid.Column width={11}>
                        <p>{actualAnswer.text}</p>
                    </Grid.Column>
                    <Grid.Column width={5}>
                        <p>{this.fixNumber(actualAnswer.result)}</p>
                    </Grid.Column>
                </Grid.Row>
                );
            })

            const tilte = ( 
                <Grid.Row key='title'>
                    <Grid.Column width={11}>
                        <p className="table-title">Respuesta</p>
                    </Grid.Column>
                    <Grid.Column width={5}>
                        <p className="table-title">Resultado</p>
                    </Grid.Column>
                </Grid.Row>
            );

            let blanckVotes = null;
            if(this.props.maxAnswers === 1){
                const totalVotation = answersId.reduce((acumulated, current)=>{
                    return acumulated + answers[current].result;
                },0);

                const whithe = this.state.actualCuorum - totalVotation;
                blanckVotes = (
                    <Grid.Row key='blank-votes'>
                        <Grid.Column width={11}>
                            <p>No Votantes</p>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <p>{this.fixNumber(whithe)}</p>
                        </Grid.Column>
                    </Grid.Row>
                );
            }
            
            /*const gone = 100 - this.state.actualCuorum;
            const goneVotes = (
                <Grid.Row key='gone-votes'>
                    <Grid.Column width={11}>
                        <p>Ausentes</p>
                    </Grid.Column>
                    <Grid.Column width={5}>
                    <p>{this.fixNumber(gone)}%</p>
                    </Grid.Column>
                </Grid.Row>
            );*/

            //let grid = [tilte, ...renderedAnswers, blanckVotes, goneVotes]
            let grid = [tilte, ...renderedAnswers, blanckVotes]

            returnedComponet =(
                <Container className={this.props.className}>
                    {question}
                    <Container className="table-container">
                        <Grid celled='internally'>
                            {grid}
                        </Grid>
                    </Container>
                </Container>

            );
        }else{
            returnedComponet = (<Loading/>)
        }
        return returnedComponet;
    }
}

export default Results;