import Axios from 'axios';

const conf = require('../../conf');

export const logger = (message, type) => {
    let info;
    let speedJs;
    try {
        info = `userAgent: ${navigator.userAgent}. appVersion: ${navigator.appVersion}. Language: ${navigator.language}. PHid: ${localStorage.getItem('PHid')}. assemblyId: ${localStorage.getItem('assemblyId')}. estateId: ${localStorage.getItem('estateId')}`; 
     } catch (error) {
        console.error(error);
        info = `No disponible. ${error}`;
    }

    try {
        speedJs = `downlink: ${navigator.connection.downlink}. effectiveType: ${navigator.connection.effectiveType}. rrt: ${navigator.connection.rtt}`;
    } catch (error) {
        speedJs = `No disponible. ${error}`;
    }


    const data = {
        id:`${localStorage.getItem('assemblyId')}-${localStorage.getItem('estateId')}`,
        message: message,
        type: type,
        info: info
    }

    const url = `${conf.baseURL}/logger/logger`
    const startTime = Date.now();
    Axios.post(url, data, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
    .then(res =>{
        const endTime = Date.now();
        const dataTime = {
            id:`${localStorage.getItem('assemblyId')}-${localStorage.getItem('estateId')}`,
            message: `SPEEDJS: ${speedJs}. LATENCY: ${endTime - startTime}`,
            type: type,
            info: info
        }
        Axios.post(url, dataTime, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
    });

}

