import React, { Component } from 'react';
import { Button, Form, Modal, Container} from 'semantic-ui-react';
import axios from 'axios';
import { toast } from 'react-toastify';
//import update from 'immutability-helper';
var conf = require('../../conf');


class EditQuestion extends Component {

  constructor(props) {
    super(props);
    this.state = {
      idQuestion:'',
      question: '',
      time:0,
      multipleAnswer:'',
      question_type_opts:[],
      question_type:'',
      answers:[],
      answersNumber:0,
      resFields:[],
      resVal:'',
      ansList:[],
    };

    this.handleRes=this.handleRes.bind(this);

  }

  componentDidMount(){
    this.setState({
      question_type_opts:[
        {key:'0', text:"Mayoria simple", value:"Mayoria simple"},
        {key:'1', text:"Mayoría favorable (Mínimo 51% para aprobación de una opción)", value:"Mayoria favorable"},
        {key:'2', text:"Mayoria calificada", value:"Mayoria calificada"}]
    });
    
  }

  getQuestionData = () => {
    axios.get(`${conf.baseURL}/question?PHid=${this.props.idPh}&assemblyId=${this.props.idAsm}`, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
        let datos = res.data.message;
        this.setState({ info: datos });
      }).catch(err => console.log(err));

  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let ansFields =[]
    this.getQuestionData()
    if (this.props !== nextProps) {
      //this.getQuestionData();
      if(this.state.info){
        this.state.info.map(qes=>{
          if(qes.id===nextProps.idQuestion){
            this.setState({
              idQuestion:qes.id,
              question:qes.data.question,
              time: qes.data.time,
              answ: qes.answers,
              question_type: qes.data.question_type,
            });
            for(let i=1;i<nextProps.ansNum+1;i++){
              if(qes.answers.length<nextProps.ansNum){
                if((i-1)<qes.answers.length){
                  ansFields[i-1]={
                    key: i,
                    name:("ans"+i), 
                    label:("Respuesta "+i),
                    type:"text",
                    value:qes.answers[i-1].data.text
                  }
                }else{
                  ansFields[i-1]={
                    key: i,
                    name:("ans"+i), 
                    label:("Respuesta "+i),
                    type:"text",
                    value:''
                  }
                }
              }else{
                ansFields[i-1]={
                  key: i,
                  name:("ans"+i), 
                  label:("Respuesta "+i),
                  type:"text",
                  value:qes.answers[i-1].data.text
                }
              }
            }
          }
        })
      }
      this.setState(
        {resFields:ansFields}
      );
      this.setState({multipleAnswer:nextProps.resLim});
    }
  }

  handleSave = () => {

    this.setState(state=> {
      state.resFields.map(it=>{
        state.answers.push({id:it.key.toString(),text:it.value});
      });
    });
    const {
      idQuestion,
      question,
      time,
      multipleAnswer,
      question_type,
    } = this.state;
    let formData = {
      'questionId':idQuestion,
      'PHid':this.props.idPh,
      'assemblyId': this.props.idAsm,
      'question': question,
      'time':time,
      'multipleAnswer':multipleAnswer,
      'question_type':question_type,
      'answers':this.state.answers
    }
    console.log("Datos a guardar - EditQuestion: ", formData);
    axios.put(`${conf.baseURL}/question`, formData, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
        let fail = res.data.error;
        if (fail) {
          //alert('Intentelo nuevamente');
          toast.error('Algo salio mal. Intentalo nuevamente',
            {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
        }
        else {
          toast.info('La pregunta se actualizo correctamente',
            {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
          this.setState({
            answers:[]
          });
          this.props.onClose();
          this.props.refrescar();
        }
      })
      .catch(err => {
        console.log(err);
        toast.error('Algo salio mal. Intentalo nuevamente',
          {
            position: 'top-center',
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
      });
  }

  handleChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  }

  handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value});
  }

  handleRes = (e) => {
    let name = e.target.name;
    let value = e.target.value;    
    this.setState(state=> {
      return state.resFields.map(it=>{
        if(it.name===name){
          it.value=value;
        }
      });
    });
  }

  render() {
    const campos = [
      { name: 'question_type', label: 'Tipo de pregunta', type: 'select', value: this.state.question_type},
      { name: 'timeCheck', label: 'Tiene tiempo limite', type: 'number'},
      { name: 'time', label: 'Tiempo limite (ms)', type: 'text', value:this.state.time},
      { name: 'question', label: 'Pregunta', type: 'text', value:this.state.question},
    ];
    return (
      <React.Fragment>

        <Modal open={this.props.open}
          onClose={this.props.onClose}
        >
          <Modal.Header>Editar una pregunta</Modal.Header>
          <Modal.Content>
            <Container>
              <Form>
                {campos.map(c => {
                  if (c.type === 'text') {
                    return (
                      <Form.Input
                        key={c.name}
                        value={c.value}
                        onChange={this.handleInput}
                        name={c.name}
                        label={c.label}
                        type={c.type}
                      />
                    );
                  }
                  else if (c.type === 'select') {
                    return (
                      <Form.Select
                        key={c.name}
                        value={c.value}
                        onChange={this.handleChange}
                        name={c.name}
                        label={c.label}
                        type={c.type}
                        options={this.state.question_type_opts}
                      />
                    );
                  }
                })}
                {this.state.resFields ?
                  this.state.resFields.map((res) =>{
                    return(
                      <Form.Input
                        key={res.name}
                        value={res.value}
                        onChange={this.handleRes}
                        name={res.name}
                        label={res.label}
                        type={res.type}
                      />
                    );
                  }):<div></div>}
              </Form>
            </Container>
          </Modal.Content>
          <Modal.Actions>
            <Button primary
              content='Editar pregunta'
              icon='save'
              onClick={()=>{
                this.handleSave()
              }}
            />
            <Button primary
              content='Cerrar pregunta'
              icon='delete'
              onClick={()=>{
                this.props.onClose()
              }}
            />
          </Modal.Actions>

        </Modal>
      </React.Fragment>
    );
  }
}

export default EditQuestion;
