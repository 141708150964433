import React, { Component } from 'react';
import { LOADING_SCREEN, QUORUM_SCREEN, VOTE_SCREEN, RESULTS_SCREEN, MAIN_SCREEN, FIREBASE_DB, PH_COLLECTION, ASSEMBLIES_COLLECTION, SCREEN_STATUS_COLLECTION, SCREEN_STATUS_DEFAULT_ID, QUORUM_COLLECTION, ESTATE_COLLECTION } from '../../../constants';
import Loading from './Loading';
import QuorumScreen from './QuorumScreen';
import VotationScreen from './VotationScreen';
import ResultsScreen from './ResultsScreen';
import MainScreen from './MainScreen';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { deleteSession } from '../../utils/deleteSession';
import { realTime } from '../../utils/realTime';
import { timeOutTimer } from '../../utils/timeOutTimer';
import { logger } from '../../utils/logger';

var conf = require('../../../conf');

class StateScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            screen: LOADING_SCREEN,
            next: MAIN_SCREEN,
            activeQuestion: null,
            lastUpdated: 0,
            activeTimer: 0,
            showType:false,
            showResults:false,
            showTimer: false
        }
        this.PHid = props.PHid;
        this.assemblyId = props.assemblyId;
        this.estateId = props.estateId;
        this.reportDeviceSentence = "Conectando Vo.Tower"
        this.representations = [];

        const assemblyDocument = FIREBASE_DB.collection(PH_COLLECTION).doc(this.PHid)
                                    .collection(ASSEMBLIES_COLLECTION).doc(this.assemblyId);
        const statusDocument = assemblyDocument.collection(SCREEN_STATUS_COLLECTION).doc(SCREEN_STATUS_DEFAULT_ID);

        const quorumDocument = assemblyDocument.collection(QUORUM_COLLECTION).doc("lastUpdated");

        const estateDocument = assemblyDocument.collection(ESTATE_COLLECTION).doc(this.estateId);
        
        const statusRTFail = "Error al intentar concetar al servidor de estados.";
        const quorumRTFail = "Error al intentar concetar al servidor de Quorum.";
        const estateRTFail = "Error al intentar concetar al servidor de Propetarios.";

        this.statusTimer = timeOutTimer(`${statusRTFail}0x2`)
        this.quorumTimer = timeOutTimer(`${quorumRTFail}0x2`)
        this.estateTimer = timeOutTimer(`${estateRTFail}0x2`)

        let statusRTFunction = (doc) => {
            try {

                const data = doc.data();
            
                const currentScreen = data.activeScreen;
                const currentQuestion = data.activeQuestion;
                if(this.state.screen !== currentScreen){
                    this.updateScreen(currentScreen);
                }  

                if(this.state.activeQuestion !== currentQuestion){
                    
                    this.setState({
                        activeQuestion: currentQuestion
                    })
                }

                if(this.state.screen === VOTE_SCREEN){

                    const currentActiveTime = data.activeTime;
                    const currentShowType = data.showType;
                    const currentShowResults = data.showResults;
                    const currentShowTimer = data.showTimer;
                    const newData = {}

                    if(this.state.activeTimer !== currentActiveTime){
                        newData.activeTimer = currentActiveTime;
                    }

                    if(this.state.showType !== currentShowType){
                        newData.showType = currentShowType;
                    }

                    if(this.state.showResults !== currentShowResults ){
                        newData.showResults = currentShowResults;
                    }

                    if(this.state.showTimer !== currentShowTimer){
                        newData.showTimer = currentShowTimer;
                    }

                    this.setState({
                        ...newData
                    })
                }
                logger("status socket ok", "log");

            } catch (error) {
                console.error(error);
                const msg = `${statusRTFail}0x3`;
                logger(`${msg}. ${error}`, "error");

            }
            clearTimeout(this.statusTimer);   
        };

        this.statusListener = realTime(statusDocument, statusRTFunction, `${statusRTFail}0x1`, this.statusTimer);

        
        let quorumRTFunction = (doc) =>{
            try {
                const curretUpdate = doc.data().time;
                if(this.state.lastUpdated !== curretUpdate){
                    if(this.state.lastUpdated !== 0){
                        this.reportDevice();
                    }
                    this.setState({
                        lastUpdated: curretUpdate
                    });
                } 
                logger("quorum socket ok", "log");

            } catch (error) {
                console.error(error);
                const msg = `${quorumRTFail}0x3`;
                logger(`${msg}. ${error}`, "error");
            }

            clearTimeout(this.quorumTimer);   

        };

        this.quorumListener = realTime(quorumDocument, quorumRTFunction, `${quorumRTFail}0x1`, this.quorumTimer);


        let estateRTFunction = (doc) =>{
            try {
                const actualRepresentaion = doc.data().representations;
                if(actualRepresentaion){
                    this.representations.forEach(element =>{
                        if(!actualRepresentaion.includes(element)){
                            toast.info(`Se ha retirado el poder de ${element}`)
                        }
                    });
                    this.representations=actualRepresentaion;
                }
                logger("estate socket ok", "log");
                
            } catch (error) {
                console.error(error);
                const msg = `${estateRTFail}0x3`;
                logger(`${msg}. ${error}`, "error");
            }
            clearTimeout(this.estateTimer);   

        }

        this.estateListener = realTime(estateDocument, estateRTFunction, `${estateRTFail}0x1`, this.estateTimer);


        this.reportDevice();
    }

    getScreenByName(){

        switch (this.state.screen){
            case LOADING_SCREEN:
                return (<Loading/>);
            case QUORUM_SCREEN:
                return (<QuorumScreen PHid={this.PHid} assemblyId={this.assemblyId}/>);
            case VOTE_SCREEN:
                return (<VotationScreen 
                            PHid={this.PHid} 
                            assemblyId={this.assemblyId} 
                            estateId={this.estateId} 
                            questionId={this.state.activeQuestion} 
                            activeTimer={this.state.activeTimer}
                            //activeTimer={localStorage.getItem('timer')? localStorage.getItem('timer'): null}
                            showType={this.state.showType}
                            showResults={this.state.showResults} showTimer={this.state.showTimer}/>);
            case RESULTS_SCREEN:
                return (<ResultsScreen PHid={this.PHid} assemblyId={this.assemblyId} questionId={this.state.activeQuestion}/>);
            default:
                return (<MainScreen/>);
        }
    }

    updateScreen = (screen) =>{
        this.setState({
            screen
        })
    }

    getStatus(){
        const url = `${conf.baseURL}/screen_status?PHid=${this.PHid}&assemblyId=${this.assemblyId}`

        Axios.get(url, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
        .then(res => {
        if(!res.data.error){
            const message = res.data.message;  
            const screen = message.activeScreen;
           
            this.updateScreen(screen);
        }
        }).catch(err => {
            const resData  = err.request; 
            const message = JSON.parse(resData.response)["message"];
            console.error(message);
            logger(`Error en ${url}. ${err}. ${message}.`)
            toast.error(`${message}`);
            if(resData.status === 401){
                deleteSession();
            }
        });

    }

    reportDevice(){

        const url = `${conf.baseURL}/quorum/report_quorum`
        const data = {
            PHid: this.PHid,
            assemblyId: this.assemblyId,
            estateId: this.estateId
        }

        Axios.post(url, data, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}} )
        .then(res =>{
            console.log(this.reportDeviceSentence);
            toast.info(this.reportDeviceSentence);
            this.reportDeviceSentence = "Quorum Reportado";
        })
        .catch(error => {
            const resData = error.request;
            try{
                if(resData.status !== 401){
                    toast.error('No se ha podido reportar el quorum');
                }else{
                    const message = JSON.parse(resData.response)["message"];
                    console.error(message);
                    toast.error(`${message}`);
                    deleteSession();
                }
            }catch(error){
                toast.error('Error');
                console.error(error);
                logger(`Error en ${url}. ${error}.`)

            }
        });
    }

    componentWillUnmount(){
        clearTimeout(this.statusTimer);
        clearTimeout(this.quorumTimer);
        clearTimeout(this.estateTimer);
    }


    render() {
        return this.getScreenByName();
    }
}

export default StateScreen;
