import React, { Component } from 'react';
import { Button, Form, Modal, Container} from 'semantic-ui-react';
import axios from 'axios';
import { toast } from 'react-toastify';
var conf = require('../../conf');


class Reports extends Component {

  constructor(props) {
    super(props);
    this.state = {
      options:[],
      report:[],
      report_opts:[],
    };

  }

  componentDidMount(){ 

    
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.open !== nextProps.open) {
      this.setState({
        open: nextProps.open
      });
    }
    this.setState({
      report_opts:[
        {key:'0', text:"Asistencia", value:"presence"},
        {key:'1', text:"Preguntas", value:"questions"},
        {key:'2', text:"Detallado", value:"detailed"}], selected:'presence' 
    });
  }

  presenceReport =(formData)=>{
    axios.post(`${conf.baseURL}/reports/presence`, formData,{headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
        let fail = res.data.error;
        if (fail) {
          toast.error('Algo salio mal. Intentalo nuevamente',
            {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
        }
        else {
          toast.info('El reporte de asistencia se genero correctamente',
            {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
          this.props.onClose();
        }
      })
      .catch(err => {
        console.log(err);
        toast.error('Algo salio mal. Intentalo nuevamente',
          {
            position: 'top-center',
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
    });
  }

  questionsReport =(formData)=>{
    axios.post(`${conf.baseURL}/reports/questions`, formData,{headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
        let fail = res.data.error;
        if (fail) {
          toast.error('Algo salio mal. Intentalo nuevamente',
            {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
        }
        else {
          toast.info('El reporte de preguntas se genero correctamente',
            {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
          this.props.onClose();
        }
      })
      .catch(err => {
        console.log(err);
        toast.error('Algo salio mal. Intentalo nuevamente',
          {
            position: 'top-center',
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
    });
  }

  detailedReport =(formData)=>{
    axios.post(`${conf.baseURL}/reports/detailed`, formData, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
        let fail = res.data.error;
        if (fail) {
          toast.error('Algo salio mal. Intentalo nuevamente',
            {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
        }
        else {
          toast.info('El reporte detallado se genero correctamente',
            {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
          this.props.onClose();
        }
      })
      .catch(err => {
        console.log(err);
        toast.error('Algo salio mal. Intentalo nuevamente',
          {
            position: 'top-center',
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
    });
  }

  handleSave = () => {

    let formData = {
      
      'assemblyId': this.props.idAsm,
      'PHid':this.props.idPh,
    }
    
    switch(this.state.reportName){
        case 'presence':
            this.presenceReport(formData);
            break;
        
        case 'questions':
            this.questionsReport(formData);
            break;

        case 'detailed':
            this.detailedReport(formData);
            break;

        default:
            this.presenceReport(formData);
            break;
    }
    
  }

  handleGet = (event, {name,value}) => {
    /*let name = e.target.name;
    let value = e.target.value;*/
    this.setState({ [name]: value});
  }

  render() {
    const campos = [
      { name: 'reportName', label: 'Reporte a generar', type: 'select', value: this.state.reportName},
    ];
    
    return (
      <React.Fragment>

        <Modal open={this.props.open}
          onClose={this.props.onClose}
        >
          <Modal.Header>Generacion de reportes</Modal.Header>
          <Modal.Content>
            <Container>
              <Form>
                {campos.map(c => {
                    return (
                    <Form.Select
                        key={c.name}
                        //value={c.value}
                        onChange={this.handleGet}
                        name={c.name}
                        label={c.label}
                        type={c.type}
                        options={this.state.report_opts}
                        defaultValue={this.state.selected}
                    />
                    );
                })}
              </Form>
            </Container>
          </Modal.Content>
          <Modal.Actions>
            <Container>
            <Button primary
              content='Generar reporte'
              icon='save'
              onClick={()=>{
                this.handleSave()
              }}
            />
            </Container>
          </Modal.Actions>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Reports;
