import { TIMEOUT } from '../../constants';
import { logger } from './logger'
import { toast } from 'react-toastify';

export const timeOutTimer = (message) => {
    return setTimeout(() => {
        logger(message, "error");
        toast.error(message); 
    }, TIMEOUT);
}
