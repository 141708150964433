import React, { Component } from 'react';
import { Button, Form, Modal, Container} from 'semantic-ui-react';
import { DateTimeInput } from 'semantic-ui-calendar-react';
import axios from 'axios';
import { toast } from 'react-toastify';
var conf = require('../../conf');

class EditAsm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      id:'',
      date:'',
      place:'',
    };
  }

  getAsmData = () => {
    axios.get(`${conf.baseURL}/assembly?PHid=${this.props.idPh}`, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
        let datos = res.data.message;
        this.setState({ info: datos });
      }).catch(err => console.log(err));

  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.info !== nextProps.info) {
      this.setState({
        id: nextProps.info.id,
        date: nextProps.info.data.date,
        place: nextProps.info.data.place,
      });
    }
  }


  handleSave = () => {
    let formData={
      'PHid': this.props.idPh,
      'place':this.state.place,
      'date': this.state.date,
      'id': this.state.id
    };
    axios.put(`${conf.baseURL}/assembly`, formData, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
    .then(res =>{
        let fail = res.error;
        if (fail) {
          toast.error('Algo salio mal. Intentalo nuevamente',
            {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
        }
        else {
          toast.info('La Asamblea ha sido actualizada.',
            {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
          this.props.onClose(); 
          this.props.refrescar(this.state.id);
        }
      })
  }

  handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
  }


  handleChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  }

  render() {
    const campos = [
      { name: 'date', label: 'Fecha', type: 'date', value: this.state.date },
      { name: 'place', label: 'Lugar', type: 'text', value: this.state.place },
    ];

    return (

        <Modal open={this.props.open}
          onClose={this.props.onClose}
        >
          <Modal.Header>Editar asamblea</Modal.Header>
          <Modal.Content>
            <Container>
              <Form>
                {campos.map(c => {
                  if (c.type === 'text') {
                    return (
                      <Form.Input
                        key={c.name}
                        value={c.value}
                        onChange={this.handleInput}
                        name={c.name}
                        label={c.label}
                        type={c.type}
                      />
                    );
                  }
                  else if (c.type === 'date') {
                    return (
                      <DateTimeInput
                        key={c.name}
                        name={c.name}
                        label={c.label}
                        value={c.value}
                        iconPosition="left"
                        dateFormat='YYYY/MM/DD Z'
                        onChange={this.handleChange}
                      />
                    );
                  }
                })}
              </Form>
            </Container>
          </Modal.Content>
          <Modal.Actions>
            <Button primary
              content='Editar Asamblea'
              icon='save'
              onClick={this.handleSave}
            />
          </Modal.Actions>
        </Modal>
    );
  }
}

export default EditAsm;
