import React, { Component } from 'react';
import {
  Container,
  Divider,
  Header,
  Segment,
  CardGroup,
  Button
} from 'semantic-ui-react';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ResponsiveContainer from './Componentes/ResponsiveContainer';
import Axios from 'axios';
import CardPh from './Componentes/PH/CardPh';
import DetailPh from './Componentes/PH/DetailPh';
import DetailAsm from './Componentes/Assemblies/DetailAssembly';
import PageFooter from './Componentes/PageFooter';
import Ph from './Componentes/PH/NewPH'
import Login from './Componentes/Login/Login';
//import HomepageHeading from './Componentes/HomepageHeading';
var conf = require('./conf');


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      admin: localStorage.getItem('usuario'),
      openPH: false,
      openAsm: false,
      listaPH: [],
      listAsm: [],
      phActual: '',
      idPHActual: '',
      listaAsambleas: [],
      url_admin: false,
      tokenAsmActual:'',
      idAsmActual:'',
      modalPh: false,
    };
  }

  componentDidMount() {
    if (this.state.admin) {
      this.getPH();
    }
    let url_admin = window.location.pathname.match(/\/admin/);
    if (url_admin !== null) {
      this.setState({ url_admin: true });
    }
    let url_token = window.location.pathname.match(/\/token\/([^/\n]*)/)
    if (url_token !== null) {
      if(url_token[1]!==null){
        this.setState({ tokenAsmActual: url_token[1] });
      }
    }
  }

  handlePh = () => {
    this.setState({ modalPh: true });
  }

  handleClosePh = () => this.setState({ modalPh: false })

  setAdmin = (user) => {
    this.setState({ admin: user }, () => {
      this.getPH();
    });
  }

  verificarStorage = () => {
    if (
      localStorage.getItem('usuario') &&
      localStorage.getItem('session')
    ) {
      this.setState(
        {
          usuario: localStorage.getItem('usuario'),
          token: localStorage.getItem('session'),
          sesionIniciada: true
        },
        () => {
          window.location = window.location.origin;
          this.props.setAdmin(localStorage.getItem('usuario'));
          //window.location.reload()
        }
      );
    }
  }

  onClosePh = () => {
    this.setState({
      openPh: false
    });
    this.getPH();
  }

  onCloseAsm = () => {
    this.setState({
      openAsm: false
    });
    this.getPH();
  }


  viewPh = (idPh) => {
    this.setState({
      phActual: idPh,
    });
    this.state.listaPH.map(ph => {
      if (ph.id === idPh) {
        this.setState({
          PhActual: ph.id,
          phInfo: ph.data,
        });
      }
    });
  }

  getPH = () => {
    Axios.get(`${conf.baseURL}/ph/`, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
        this.setState({ listaPH: res.data.message});
      }).catch(err => console.log(err));
  }

  getAsmActual = (idAsm) => {
    Axios.get(`${conf.baseURL}/assembly?PHid=${this.state.phActual}`, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
        this.setState({listAsm : res.data.message});
        
        this.state.listAsm.map(asm => {
          if (asm.id === idAsm) {
            this.setState({
              infoAsm : asm
            });
          }
        });
        this.setState({openAsm:true})
      }).catch(err => console.log(err));
  }

  viewPhs = () => {
    window.location.reload();
  }

  showLogin =()=>{
    return(
      <ResponsiveContainer 
          setAdmin={this.setAdmin}
          showHome={true}
          url_admin={this.state.url_admin}
          phActual={this.state.phActual}
          tokenAsmActual={this.state.tokenAsmActual}
          openPh={this.state.openPh}
          onClosePh={this.onClosePh}
        >
          <ToastContainer
            position="top-center"
            autoClose={10000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
          <Segment inverted>
          <Login
            verificar={()=>{this.verificarStorage();}}
          />
          </Segment>
          <PageFooter/>
        </ResponsiveContainer>
    );
  }

  renderUserHome = () => {
    return (
      <ResponsiveContainer 
        setAdmin={this.setAdmin}
        url_admin={this.state.url_admin}
        tokenAsmActual={this.state.tokenAsmActual} 
      >
        <ToastContainer
          position="top-center"
          autoClose={10000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
       <PageFooter/>
      </ResponsiveContainer>
    );
  }


  renderAdminHome = () => {
    if(this.state.admin){
      if(this.state.PhActual){
        if(!this.state.openAsm){
          return (
            <ResponsiveContainer 
              setAdmin={this.setAdmin}
              url_admin={this.state.url_admin}
              phActual={this.state.phActual}
              openPh={this.state.openPh}
              onClosePh={this.onClosePh}
            >
              <ToastContainer
                position="top-center"
                autoClose={10000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
              />
              <Segment>
                <Container>
                <DetailPh
                  info={this.state.phInfo}
                  id={this.state.phActual}
                  onClick={this.getAsmActual}
                  viewPhs={this.viewPhs}
                />
                </Container>
              </Segment>
              <PageFooter/>
            </ResponsiveContainer>
          );
        }else{
          return (
            <ResponsiveContainer 
              setAdmin={this.setAdmin}
              url_admin={this.state.url_admin}
              phActual={this.state.phActual}
              openPh={this.state.openPh}
              onClosePh={this.onClosePh}
            >
              <ToastContainer
                position="top-center"
                autoClose={10000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
              />
              <Segment>
                <Container>
                <DetailAsm
                  info={this.state.infoAsm}
                  id={this.state.phActual}
                  openAsm={this.state.openAsm}
                  onClick={this.getAsmActual}
                  onCloseAsm={this.onCloseAsm}
                  refrescar={this.viewPh}
                  refrescarAsm={this.getAsmActual}
                />
                </Container>
              </Segment>
              <PageFooter/>
            </ResponsiveContainer>
          );
        }
      }else{
        return (
          <ResponsiveContainer 
            setAdmin={this.setAdmin}
            showHome={true}
            url_admin={this.state.url_admin}
            phActual={this.state.phActual}
            tokenAsmActual={this.state.tokenAsmActual}
            openPh={this.state.openPh}
            onClosePh={this.onClosePh}
          >
            <ToastContainer
              position="top-center"
              autoClose={10000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
            <Segment inverted>
              <Button
                content='Nueva PH'
                icon='plus circle'
                onClick={this.handlePh}
                as='a' 
              />
              <Divider/>
              <Header as='h3' style={{ fontSize: '2em' }}>
                Elige tu conjunto:
              </Header>
              <Divider />
              <Container>
                <CardGroup itemsPerRow={6}>
                  {this.state.listaPH ?
                    this.state.listaPH.map((pha)=>{
                      return(
                        <CardPh
                          key={pha.id}
                          nombrePh={pha.data.name}             
                          idPh={pha.id}
                          managementCompanyContact={pha.data.managementCompanyContact}
                          managementCompanyContactPhone={pha.data.managementCompanyContactPhone}
                          onClick={this.viewPh}
                        />
                      );
                    }): <div></div>} 
                </CardGroup>
              </Container>
            </Segment>
            <PageFooter/>
            <Ph
              open={this.state.modalPh}
              onClose={this.handleClosePh}
            />
          </ResponsiveContainer>
        );  
      }
    }
  }

  render() {
    if(!this.state.admin){
      if(this.state.url_admin){
        return this.showLogin();
      }else{
        return this.renderUserHome();
      }
    }else{
      return this.renderAdminHome();
    }
  }
}
export default App;
