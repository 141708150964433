import React, { Component } from 'react';
import { Card,Button } from 'semantic-ui-react';

class CardPh extends Component {

  render() {
    return (
      <Card>
        <Card.Content>
          <Card.Header>{this.props.nombrePh}</Card.Header>
          <Card.Description>Nit: {this.props.idPh}</Card.Description>
          <Card.Meta>
            Contacto: {this.props.managementCompanyContact}
          </Card.Meta>
          <Card.Meta>
            Telefono contacto: {this.props.managementCompanyContactPhone}
          </Card.Meta>
        </Card.Content>
        <Card.Content extra>
          <Button
            content='Ver PH'
            color='purple'
            as='a'
            onClick={() => {this.props.onClick(this.props.idPh);}}
          />
        </Card.Content>
      </Card>
    );
  }
}

export default CardPh;