import React, { Component } from 'react';
import { Button, Form, Modal} from 'semantic-ui-react';
import axios from 'axios';
import { toast } from 'react-toastify';
var conf = require('../../conf');

class NewEstate extends Component {

  constructor(props) {
    super(props);

    this.state = {
      PHid:'',
      idState:'',
      ownerName:'',
      ownerCoefficient:'',
      representedCoefficient:'',
      email:'',
      accessToken:'',
    };
  }

  handleSave = () => {
    let coef;
    try {
      coef = Number((this.state.ownerCoefficient).replace(",",".")*1);
    } catch (error) {
      coef = this.state.ownerCoefficient
    }
    let formData={
      'id': this.state.idState,
      'ownerName':this.state.ownerName,
      'coefficient':coef,
      'email': this.state.email,
      'PHid': this.props.idPh,
      'assemblyId': this.props.idAsm,
      'accessToken':this.state.accessToken
    };
    console.log("Coeficiente a guardar:", formData.coefficient);
    axios.post(`${conf.baseURL}/estate`, formData, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
    .then(()=> {
        console.log("Respuesta Ok");
        toast.info('El Inmbueble ha sido creado.',
          {
            position: 'top-center',
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        this.props.onClose(); 
        this.props.refrescar();
    }).catch(err => {
      console.log(err);
      if(err.response.data.message.code===6){
        toast.error('El token ya existe en la base de datos, ingrese uno nuevo',
        {
          position: 'top-center',
          autoClose: 10000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }else{
        console.log(err);
        toast.error('Algo salio mal. Intentalo nuevamente',
          {
            position: 'top-center',
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
      }  
    });
  }

  handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if(name=='ownerCoefficient'){
      value=Number(value.replace(",",".")*1)
    }
    this.setState({ [name]: value });
  }

  render() {
    const campos = [
      { name: 'idState', label: 'Id del inmueble', type: 'text'},
      { name: 'ownerName', label: 'Nombre del copropietario:', type: 'text'},
      { name: 'ownerCoefficient', label: 'Coeficiente de copropiedad:', type:'text'},
      //{ name: 'representedCoefficient', label: 'Coeficiente representado:', type: 'text'},
      { name: 'email', label: 'Email:', type: 'text'},
      { name: 'accessToken', label: 'Token de asamblea:', type: 'text'},
    ];

    return (
      <Modal
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <Modal.Header>Crear Inmueble {this.state.idState}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form>
              {campos.map(c => {
                  return (
                    <Form.Input
                      key={c.name}
                      value={this.state.c}
                      onChange={this.handleInput}
                      name={c.name}
                      label={c.label}
                      type={c.type}
                    />
                  );
              })}
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button primary
            content='Crear Inmueble'
            icon='save'
            onClick={this.handleSave}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default NewEstate;
