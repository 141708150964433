import React, { Component } from 'react';
import { Button, Form,Grid, Segment, Header } from 'semantic-ui-react';
import axios from 'axios';
import { toast } from 'react-toastify';


var conf = require('../../conf');

class LoginToken extends Component {

  constructor(props) {
    super(props);

    this.state = {
      correo: '',
      token: this.props.token,
    };

    this.onLogin = this.props.onLoing;
  }
    
  handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
  }

  handleLoginToken = () => {
    const { correo, token } = this.state;
    //Verificar y notificar errores en la información

    axios.post(`${conf.baseURL}/login/login-user`, {
      accessToken: token,
      email: correo
    }).then(res => {
      if (res.data.error) {
        //alert(res.data.message);
        toast.error(`${res.data.message}`,
          {
            position: 'top-center',
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
      }
      else {
        const data = res.data.message;
        localStorage.setItem('session',  data.session );
        localStorage.setItem('PHid', data.PHid);
        localStorage.setItem('assemblyId', data.assemblyId);
        localStorage.setItem('estateId', data.estateId);

        this.onLogin();
      }
    }).catch(
      function (error) {
        console.log(error.response.data.message);
        
        toast.error(`${error.response.data.message}`,
          {
            position: 'top-center',
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
      }
    );
  };

  render() {
    
    return (
      <Grid textAlign='center' style={{ height: '100%', margin: '40px auto' }} verticalAlign='middle'>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as='h2' color='black' textAlign='center' inverted>
            Ingresa a la asamblea
          </Header>
          {/*<Modal.Content image>*/}
            <Form>
              <Segment stacked>
                <Form.Input fluid icon='user'
                  iconPosition='left'
                  placeholder='Correo Electronico'
                  name='correo'
                  onChange={this.handleInput}
                />
                <Form.Input
                  fluid
                  icon='lock'
                  iconPosition='left'
                  placeholder='Token'
                  name='token'
                  onChange={this.props.token_asm ? this.props.token_asm : this.handleInput}
                  value = {this.state.token}
                />
              </Segment>
            </Form>
            <Grid.Row className="login-buton">

              <Button
                className ="btn-user-primary"
                size='large'
                onClick={this.handleLoginToken}>
                Iniciar Sesión
              </Button>
            </Grid.Row>
          {/*</Modal.Content>*/}
        </Grid.Column>
      </Grid>
    
    );
  }
}

export default LoginToken;
