import React, { Component } from 'react';
import { Button, Form, Modal} from 'semantic-ui-react';
import axios from 'axios';
import { toast } from 'react-toastify';
var conf = require('../../conf');

class EditPh extends Component {

  constructor(props) {
    super(props);

    this.state = {
      name:'',
      id:'',
      address:'',
      managementCompany:'',
      managementCompanyPhone:'',
      managementCompanyContact:'',
      managementCompanyContactPhone:'',
      maxRepresentations:'',
    };
  }

  getPhData = () => {
    axios.get(`${conf.baseURL}/ph?PHid=${this.props.idPh}`, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
        let datos = res.data.message;
        this.setState({ info: datos });
      }).catch(err => console.log(err));

  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.info !== nextProps.info) {
      this.setState({
        name: nextProps.info.name,
        address: nextProps.info.address,
        managementCompany: nextProps.info.managementCompany,
        managementCompanyContact: nextProps.info.managementCompanyContact,
        managementCompanyContactPhone: nextProps.info.managementCompanyContactPhone,
        managementCompanyPhone: nextProps.info.managementCompanyPhone,
        maxRepresentations: nextProps.info.maxRepresentations,
      });
    }
  }


  handleSave = () => {
    let formData={
      'id': this.props.idPh,
      'name':this.state.name,
      'address': this.state.address,
      'managementCompany': this.state.managementCompany,
      'managementCompanyContact':this.state.managementCompanyContact,
      'managementCompanyContactPhone':this.state.managementCompanyContactPhone,
      'managementCompanyPhone': this.state.managementCompanyPhone,
      'maxRepresentations': this.state.maxRepresentations
    };
    axios.put(`${conf.baseURL}/ph`, formData, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
        let fail = res.error;
        if (fail) {
          toast.error('Algo salio mal. Intentalo nuevamente',
            {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
        }
        else {
          toast.info('Tu Ph ha sido actualizada.',
            {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
          this.props.onClose(); 
          this.props.refrescar();
        }
      }).catch(function (Error) {
        if (Error.response.status === 500) {
          toast.error(`${Error.response.data}`,
            {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
          console.log(Error.response.data);
          console.log(Error.response.status);
          console.log(Error.response.headers);
        } else if (Error.request) {
          console.log(Error.request);
        } else {
          console.log('Error: ', Error.message);
        }
        console.log(Error.config);
      });
  }

  handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
  }


  handleChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  }

  render() {
    const campos = [
      { name: 'idPh', label: 'Nit de la PH', type: 'text' ,value:this.props.idPh},
      { name: 'name', label: 'Nombre de la PH:', type: 'text' ,value:this.state.name},
      { name: 'address', label: 'Dirección PH:', type: 'text',value:this.state.address},
      { name: 'managementCompany', label: 'Empresa administradora:', type: 'text', value:this.state.managementCompany },
      { name: 'managementCompanyPhone', label: 'Telefono empresa administradora:', type: 'text',value:this.state.managementCompanyPhone },
      { name: 'managementCompanyContact', label: 'Contacto empresa administradora:', type: 'text',value:this.state.managementCompanyContact },
      { name: 'managementCompanyContactPhone', label: 'Telefono contacto empresa administradora:', type: 'text', value:this.state.managementCompanyContactPhone },
      { name: 'maxRepresentations', label: 'Numero maximo de representaciones:', type: 'text', value:this.state.maxRepresentations },
    ];

    return (
      <Modal
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <Modal.Header>Editar Ph {this.state.namePh}</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            {/* <Header>{this.props.id}</Header> */}
            <Form>
              {campos.map(c => {
                  return (
                    <Form.Input
                      key={c.name}
                      value={c.value}
                      onChange={this.handleInput}
                      name={c.name}
                      label={c.label}
                      type={c.type}
                    />
                  );
              })}
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button primary
            content='Editar Ph'
            icon='save'
            onClick={this.handleSave}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default EditPh;
