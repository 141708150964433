import React, { Component } from 'react';
import LoginToken from '../Login/LoginToken';
import StateScreen from './StateScreen/StateScren';
import './styles/userScreen.css'


class UserScreen extends Component {

  constructor(props) {
    super(props);
    this.getToken();
    const user = this.getUserFromMemory();
    let logged = false;

    if(user.estateId){
      this.props.showLogoutEvent();
      logged = true;
    }

    this.state = {
      token: this.getToken(),
      logged,
      user
    }
  }

  getToken(){
    try{
      const url = window.location.href;
      return url.split("token")[1].substr(1);
    }catch (error){
      return "";
    }
  }

  getUserFromMemory(){
    return {
      PHid: localStorage.getItem('PHid'),
      assemblyId: localStorage.getItem('assemblyId'),
      estateId: localStorage.getItem('estateId')
    }
  }

  setLoginStatus(status){
    this.setState({
      logged:status
    })
  }

  onSuccessLogin = () =>{
    this.setState({
      user: this.getUserFromMemory()
    })
    this.props.showLogoutEvent();
    this.setLoginStatus(true);

  }

  render(){

    let returnedComponet;

    let style = {
      "minHeight": (window.innerHeight - 221) + "px"
  }

    if (this.state.logged){
      returnedComponet = <StateScreen PHid = {this.state.user.PHid} assemblyId={this.state.user.assemblyId} estateId={this.state.user.estateId}/>
    }else{
      returnedComponet = (
        <LoginToken
          open={false}
          onClose={false}
          token = {this.state.token}
          onLoing = {this.onSuccessLogin}
        />
      );
    }

    return (
      <div className="user-container" style={style}>
        {returnedComponet}
      </div>
    );
  }
}

export default UserScreen;
