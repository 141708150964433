import React, { Component } from 'react';
import { Card,Button} from 'semantic-ui-react';
import axios from 'axios';
import Timer from '../UserScreen/Timer.js'
var conf = require('../../conf');

class CardQuestion extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      multipleAnswer:'No',
      listAnswers:[],
      status: '',
      idQuestion:'',
      idPh:'',
      idAsm:'',
      showTimer:false,
      showRT:false,
      texto:'Mostrar timer',
      textoRT:'Ver resultados RT',
      textoTipo:'Mostrar tipo pregunta',
      current:null,
    }
    this.onDelete=this.onDelete.bind(this);
    this.timeOut= null;
    this.currentTime=Date.now();
  }

  componentDidMount(){
    this.setState({listAnswers: this.props.info.answers})
    //this.setState({status: this.props.info.status})
    this.setState({
      status:this.props.info.data.status, 
      idQuestion:this.props.idQuestion, 
      idPh:this.props.idPh,
      idAsm: this.props.idAsm,
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({listAnswers: nextProps.info.answers})
    if (this.props.info !== nextProps.info) {
      this.setState({
        status:this.props.info.data.status, 
        idQuestion:this.props.idQuestion, 
        idPh:this.props.idPh,
        idAsm: this.props.idAsm,
      });
    }
  }

  
  onDelete(){
    let formData={
      'questionId':this.state.idQuestion,
      'PHid':this.state.idPh,
      'assemblyId': this.state.idAsm,
    }
    axios.delete(`${conf.baseURL}/question`, {data:formData, headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
    .then(res => {
      console.log(res);
      this.props.refrescar()
    }).catch(err => console.log(err));
  }

  showTimer = (showTimer) =>{
    this.setState(st=>{
      st.showTimer=showTimer;
    });
    let formData ={
      'PHid':this.state.idPh,
      'assemblyId': this.state.idAsm,
    }
    if(showTimer){
      axios.post(`${conf.baseURL}/screen_status/show_timer`,formData,{headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
        console.log(res);
      }).catch(err => console.log(err)); 
    }else{
      axios.post(`${conf.baseURL}/screen_status/hide_timer`,formData,{headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
        console.log(res);
      }).catch(err => console.log(err)); 
    }
  }

  finishQuestion = () =>{
    this.modifyQuestionStatus("finish");
    localStorage.removeItem('timer');
  }

  showType = (showType) =>{
    this.setState(st=>{
      st.showType=showType;
    });
    let formData ={
      'PHid':this.state.idPh,
      'assemblyId': this.state.idAsm,
    }
    if(showType){
      axios.post(`${conf.baseURL}/screen_status/show_type`,formData,{headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
        console.log(res);
      }).catch(err => console.log(err)); 
    }else{
      axios.post(`${conf.baseURL}/screen_status/hide_type`,formData,{headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
        console.log(res);
      }).catch(err => console.log(err)); 
    }
  }

  modifyQuestionStatus(status){
    
    if(status==='activate'){
      this.setState(st=>{st.texto="Ocultar timer"});
      this.props.questionStatus('activate',this.state.idQuestion,this.props.info.data.time);
    }else if(status==='cancel'){
      this.props.questionStatus('cancel',this.state.idQuestion,0);
    }else if(status==='finish'){
      this.props.questionStatus('finish',this.state.idQuestion,0);
    }
  }

  viewResultsRT = (showRT) =>{
    this.setState(st=>{
      st.showRT=!showRT;
    });

    this.props.viewResults(true,this.props.idQuestion, this.props.info.data.multipleAnswer);
  }

  status=()=>{
    if(this.state.status ==='activa'){
      return(
        <Card.Content extra>
          <Button
            content='Ver resultados RT'
            color='purple'
            as='a'
            onClick={() => {
              this.props.viewResults(true,this.props.idQuestion, this.props.info.data.multipleAnswer);
            }}
          />
          <div></div>
          <br/>
          {this.props.info.data.time !=='0'?
            <Button
            content={this.state.texto}
            color='purple'
            as='a'
            onClick={() => {
                if(this.state.showTimer){
                  this.setState({texto:"Mostrar timer"})
                }else{
                  this.setState({texto:"Ocultar timer"})
                }
                this.showTimer(!this.state.showTimer);
              }}
            />:null
          }
          <div></div>
          {this.props.info.data.time !=='0'?
            <br/>:<div></div>
          } 
          <Button
            content={this.state.textoTipo}
            color='purple'
            as='a'
            onClick={() => {
              if(this.state.showType){
                this.setState({textoTipo:"Mostrar tipo pregunta"})
              }else{
                this.setState({textoTipo:"Ocultar tipo pregunta"})
              }
              this.showType(!this.state.showType);
            }}
          /> 
          <div></div>
          <br/>
          <Button
            content='Finalizar Pregunta'
            color='purple'
            as='a'
            onClick={() => {
              this.modifyQuestionStatus("finish")
            }}
          />  
          <div></div>
          <br/>
          <Button
            content='Cancelar Pregunta'
            color='purple'
            as='a'
            onClick={() => {
              this.modifyQuestionStatus("cancel")
            }}
          />
        </Card.Content>
      );
    }else if(this.state.status ==='desactivada'){
      return(
        <Card.Content extra>
            <Button
              content='Activar'
              color='purple'
              as='a'
              onClick={() => {
                this.modifyQuestionStatus("activate");
              }}
            />
            <div></div>
            <br/>
            <Button
              content='Editar Pregunta'
              color='purple'
              as='a'
              onClick={() => {this.props.edit(this.props.idQuestion);}}
            />
            <div></div>
            <br/>
            <Button
              content='Borrar Pregunta'
              color='purple'
              as='a'
              onClick={this.onDelete}
            />
        </Card.Content>
      );
    }else if(this.state.status==='cancelada'){
      return(
        <Card.Content extra>
          <Card.Header>
            La pregunta ha sido cancelada
          </Card.Header>
        </Card.Content>
      );
    }else if(this.state.status==='finalizada'){
      return(
        <Card.Content extra>
          <Button
            content='Ver resultados'
            color='purple'
            as='a'
            onClick={() => {this.props.viewResults(false,this.props.idQuestion);}}
          />
          <div></div>
          <br/>
        </Card.Content>
      );
    }
  }

  render() {
    return (
      <Card>
        <Card.Content>
          <Card.Header>{this.props.info.data.question}</Card.Header>
          {this.state.listAnswers ?
            this.state.listAnswers.map(ans=>{
              return  (
                <Card.Description key={ans.id}>Opción {ans.id}: {ans.data.text}</Card.Description>
              );
          }):<div></div>}
          <Card.Description>Contestaciones permitidas: {this.props.info.data.multipleAnswer}</Card.Description>
          <Card.Meta>
            {this.props.info.data.status === "activa"?
              this.props.info.data.time==='0'?
                <p className='text'>Pregunta sin tiempo limite</p>:
                <Timer 
                  className='admin-timer' 
                  activeTimer={this.props.current} 
                  time={this.props.info.data.time} 
                  showTimer={true} 
                  //qTimer={this.finishQuestion}
                />
              :<p className='text'>Tiempo: {this.props.info.data.time}</p>
            }
          </Card.Meta>
          <Card.Meta>
            <p className='text'>Tipo pregunta: {this.props.info.data.question_type}</p>
          </Card.Meta>
        </Card.Content>
        {this.status()}
      </Card>
    );
  }
}

export default CardQuestion;