import React, { Component } from 'react';
import { Button, Form, Modal, Container} from 'semantic-ui-react';
import axios from 'axios';
import { toast } from 'react-toastify';
var conf = require('../../conf');


class Representations extends Component {

  constructor(props) {
    super(props);
    this.state = {
      options:[],
      optionsAllowed:[],
      info:[],
      attorney:'',
      represented:'',
    };

  }

  componentDidMount(){
    
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.info !== nextProps.info) {
      this.setState({
        info: nextProps.info
      });
    }
    let estateIds=[];
    nextProps.info.map((it,index)=>{
        estateIds[index]={ key:index, text:it.id, value:it.id};
    });
    this.setState({
        options:estateIds
    });
  }

  handleSave = () => {

    let formData = {
      
      'assemblyId': this.props.idAsm,
      'PHid':this.props.idPh,
      'attorney':this.state.attorney,
      'represented':this.state.represented,
    }
    
    axios.post(`${conf.baseURL}/representations/add`, formData, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
        let fail = res.data.error;
        if (fail) {
          toast.error('Algo salio mal. Intentalo nuevamente',
            {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
        }
        else {
          toast.info('El poder se asigno correctamente',
            {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
          this.props.onClose();
          this.props.refrescar();
        }
      })
      .catch(err => {
        console.log(err);
        toast.error('Algo salio mal. Intentalo nuevamente',
          {
            position: 'top-center',
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
      });
  }

  handleRemove = () => {

    let formData = {
      'assemblyId': this.props.idAsm,
      'PHid':this.props.idPh,
      'attorney':this.state.attorney,
      'represented':this.state.represented,
    }
    
    axios.post(`${conf.baseURL}/representations/remove`, formData, {headers: {Authorization: `Bearer ${localStorage.getItem('session')}`}})
      .then(res => {
        let fail = res.data.error;
        if (fail) {
          toast.error('Algo salio mal. Intentalo nuevamente',
            {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
        }
        else {
          toast.info('El poder se asigno correctamente',
            {
              position: 'top-center',
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
          this.props.onClose();
          this.props.refrescar();
        }
      })
      .catch(err => {
        console.log(err);
        toast.error('Algo salio mal. Intentalo nuevamente',
          {
            position: 'top-center',
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
      });
  }

  


  handleChange = (event, { name, value }) => {

    this.setState({ [name]: value });
    let getIds=[]
    this.state.options.map((it,index)=>{
        if(it.value !== value){
            getIds.push(it);
        }
    });
    
    this.setState({
        optionsAllowed:getIds
    });
  }

  handleGet = (event, {name,value}) => {
    /*let name = e.target.name;
    let value = e.target.value;*/
    this.setState({ [name]: value});
  }

  render() {
    const campos = [
      { name: 'represented', label: 'Inmueble que otorga poder', type: 'select'},
      { name: 'attorney', label: 'Inmueble que recibe poder', type: 'select'},
    ];
    
    return (
      <React.Fragment>

        <Modal open={this.props.open}
          onClose={this.props.onClose}
        >
          <Modal.Header>Gestion de poderes</Modal.Header>
          <Modal.Content>
            <Container>
              <Form>
                {campos.map(c => {
                    if(c.name === 'represented'){
                      return (
                        <Form.Select
                          key={c.name}
                          value={this.state.c}
                          onChange={this.handleChange}
                          name={c.name}
                          label={c.label}
                          type={c.type}
                          options={this.state.options}
                        />
                      );
                    }else if(c.name ==='attorney'){
                      return (
                        <Form.Select
                          key={c.name}
                          value={this.state.c}
                          onChange={this.handleGet}
                          name={c.name}
                          label={c.label}
                          type={c.type}
                          options={this.state.optionsAllowed}
                        />
                      );
                    }
                })}
              </Form>
            </Container>
          </Modal.Content>
          <Modal.Actions>
            <Container>
            <Button primary
              content='Retirar poder'
              icon='delete'
              onClick={()=>{
                this.handleRemove()
              }}
            />
            <div></div>
            <br></br>
            <Button primary
              content='Asignar poder'
              icon='save'
              onClick={()=>{
                this.handleSave()
              }}
            />
            </Container>
          </Modal.Actions>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Representations;
