import React, { Component } from 'react';
import { Button, Form, Modal, Container} from 'semantic-ui-react';


class ConfigQuestion extends Component {

  constructor(props) {
    super(props);
    this.state = {
      answersNumber:0,
      responseLimit:0,
      options:[],
      optionsAllowed:[],
      info:[]
    };

  }

  componentDidMount(){
    
    let val=[];
    for(let i=1; i<16;i++){
      val[i-1]={key: i, text: i, value: i}
    }
    this.setState({
      options:val
    });
    let ansAllowed=[]
    for(let i=1;i<this.state.answersNumber+1;i++){
      ansAllowed[i-1]={key:i, text:i, value:i}
    }
    this.setState({optionsAllowed: ansAllowed});
  }

  handleChange = (event, { name, value }) => {
    
    if(name==='answersNumber'){
      let ansAllowed=[]
      for(let i=1;i<value+1;i++){
        ansAllowed[i-1]={key:i, text:i, value:i}
      }
      this.setState({optionsAllowed:ansAllowed});
      this.setState({ [name]: value });
    }else{
      this.setState({ [name]: value });
    }
    /*if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }*/
    this.setState({info:[this.state.answersNumber, this.state.responseLimit]})
  }

  handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value})
  }
  action(){
    if(this.props.action){
      return(
        <Modal.Actions>
          <Button primary
            content='Crear pregunta'
            icon='save'
            onClick={()=>{
              this.props.onCreate(this.state.answersNumber, this.state.responseLimit)}}
          />
        </Modal.Actions>
      );
    }else{
      return(
        <Modal.Actions>
          <Button primary
            content='Editar pregunta'
            icon='save'
            onClick={()=>{
              this.props.onEdit(this.state.answersNumber, this.state.responseLimit)}}
          />
        </Modal.Actions>
      )
    }    
  }

  render() {
    const campos = [
      { name: 'answersNumber', label: 'Numero de respuestas', type: 'select'},
      { name: 'responseLimit', label: 'Contestaciones permitidas', type: 'select'},
    ];
    return (
      <React.Fragment>

        <Modal open={this.props.open}
          onClose={this.props.onClose}
        >
          <Modal.Header>Crear una pregunta</Modal.Header>
          <Modal.Content>
            <Container>
              <Form>
                {campos.map(c => {
                    if(c.name === 'answersNumber'){
                      return (
                        <Form.Select
                          key={c.name}
                          value={this.state.c}
                          onChange={this.handleChange}
                          name={c.name}
                          label={c.label}
                          type={c.type}
                          options={this.state.options}
                        />
                      );
                    }else if(c.name ==='responseLimit'){
                      return (
                        <Form.Select
                          key={c.name}
                          value={this.state.c}
                          onChange={this.handleChange}
                          name={c.name}
                          label={c.label}
                          type={c.type}
                          options={this.state.optionsAllowed}
                        />
                      );
                    }
                })}
              </Form>
            </Container>
          </Modal.Content>
          {this.action()}
        </Modal>
      </React.Fragment>
    );
  }
}

export default ConfigQuestion;
